<template>
  <div class="popup-background">
    <div class="popup">
      <button class="popup-close" @click="this.$store.dispatch('auth/logout')">
        &#10005;
      </button>
      <div class="title">
        Looks like you are already logged in from more than 3 devices!
      </div>
      <div class="sub-text">
        To continue logging in, remove at least one device from the below list
      </div>
      <ul class="device-list">
        <li v-for="(device_detail, index) in device_info_parsed" :key="index">
          <div class="device-info">
            <span class="s-no">{{ index + 1 }}</span>
            <span class="icon"></span>
            <span class="device"
              >{{ device_detail.device }}
              <span class="duration"
                >Last active - {{ device_detail.la }}</span
              ></span
            >
          </div>
          <button
            class="btn popup-btn"
            @click="sendDeleteRequest([device_detail.payload], 'single')"
          >
            Log out
          </button>
        </li>
      </ul>

      <button
        class="btn popup-btn"
        @click="
          sendDeleteRequest(
            device_info_parsed.map((di) => di.payload),
            'all'
          )
        "
      >
        Log Out from all devices
      </button>

      <p class="link">
        <a href="https://www.thehindugroup.com/termsofuse.html" target="_blank">Terms & conditions</a><span>&nbsp;&nbsp;|&nbsp;&nbsp;</span
        ><a href="https://forms.office.com/r/tz7UETzxUs" target="_blank">Institutional Subscriber</a>
      </p>
    </div>
  </div>
</template>

<script>
import UAParser from "ua-parser-js";
import { mapActions } from "vuex";

const uaParser = new UAParser("user-agent");

export default {
  props: {
    device_info: {
      type: Array,
      required: true,
    },
  },
  computed: {
    device_info_parsed() {
      return this.device_info.map((di) => {
        const key = Object.keys(di)[0];
        const val = Object.values(di)[0];

        return {
          device: this.getDeviceDetails(val.split(":")[1]),
          la: new Date(parseInt(key.split(":")[1])).toDateString(),
          key: val.split(":")[0],
          id: key,
          payload: {
            id: key,
            key: val.split(":")[0],
          },
        };
      });
    },
  },
  methods: {
    ...mapActions("reader", ["resetSessionLimit"]),
    async sendDeleteRequest(payload, type = "single") {
      await fetch(process.env.VUE_APP_SESSION_URL, {
        method: "POST",
        headers: {
          "X-Client-Auth": window?.tp?.pianoId?.getToken(),
        },
        body: JSON.stringify({
          session_ids: payload,
          type,
        }),
      })
        .then((res) => {
          if (res.ok) this.resetSessionLimit();
        })
        .then((data) => console.log(data));
    },
    getDeviceDetails(userAgentString) {
      uaParser.setUA(userAgentString);
      const uaResult = uaParser.getResult();

      let device = uaResult.os.name ?? "Unknown OS";
      if (uaResult.os.version) {
        device += " " + uaResult.os.version;
      }

      if (uaResult.browser.name) {
        device += " - " + uaResult.browser.name;
      }

      return device;
    },
  },

  mounted() {
    console.log("user agent details");
    console.log(
      this.device_info.map((di) => this.getDeviceDetails(Object.values(di)[0]))
    );
  },
};
</script>

<style scoped>
.link a {
  color: white !important;
}

.popup-background {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.popup {
  width: 100%;
  max-width: 800px;
  height: auto;
  background: #000000;
  padding: 50px 100px 50px 150px;
  color: #ffffff;
  position: relative;
}

.popup .popup-close {
  color: white;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  font-weight: 200;
  line-height: 20px;
}

.popup .title {
  font-family: "Merriweather", serif;
  font-size: 18px;
  line-height: normal;
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 50px;
}

.popup .title::before {
  content: "";
  width: 50px;
  height: 50px;
  background: url("../../public/icons/h-circle-white.svg") no-repeat center;
  background-size: 50px 50px;
  display: block;
  position: absolute;
  left: -65px;
}

.popup .sub-text {
  font-family: "Merriweather", serif;
  font-size: 18px;
  line-height: normal;
  margin: 15px 0;
}

.popup .popup-btn {
  background-color: #ffffff !important;
  color: #000000 !important;
  font-weight: bold;
  width: max-content;
  min-width: 111px;
  height: 34px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 12px;
  padding: 9px 10px 13px 20px;
  text-transform: uppercase;
  margin: 25px auto;
  display: block;
  font-family: "Merriweather Sans", sans-serif;
  transition: all 0.3s;
}

.popup .popup-btn:focus {
  box-shadow: none;
  outline: none;
}

.popup .popup-btn:after {
  content: "→";
  position: relative;
  opacity: 0;
  top: -1px;
  right: 15%;
  transition: 0.3s;
}

.popup .popup-btn:hover {
  padding-right: 20px;
  padding-left: 10px;
  color: #000000;
  text-decoration: none;
  outline: none;
}

.popup .popup-btn:hover::after {
  opacity: 1;
  right: -8px;
}

.popup .device-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.popup .device-list li {
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup .device-list li > div {
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.popup .device-list li .s-no {
  font-family: "Merriweather Sans", sans-serif;
  font-size: 16px;
  text-align: right;
  width: 10px;
}

.popup .device-list li .icon {
  content: "";
  width: 20px;
  height: 20px;
  background: url("../../public/icons/profile-white.svg") no-repeat center;
  background-size: 20px 20px;
  display: block;
  margin: 4px 10px 0;
}

.popup .device-list li .device {
  font-family: "Merriweather Sans", sans-serif;
  font-size: 16px;
  text-align: left;
  line-height: normal;
}

.popup .device-list li .device span {
  display: block;
  font-family: inherit;
}

.popup .device-list li .popup-btn {
  margin: 0 0;
}

.popup p.link {
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 200;
  text-align: center;
  font-size: 14px;
  margin: 20px auto 0;
}

.popup p.link a {
  font-weight: inherit;
  font-family: inherit;
  text-decoration: underline;
  text-underline-offset: 3px;
  transition: all 0.3s;
}

.popup p.link a:hover {
  text-decoration: none;
}

.popup p.link span {
  color: #555555;
  font-size: 18px;
  line-height: 0;
  position: relative;
  top: 3px;
}

@media screen and (max-width: 700px) {
  .popup {
    padding: 40px 40px 40px 90px;
  }
}

@media screen and (max-width: 570px) {
  .device-info {
    margin-right: 4px;
  }

  .popup {
    width: 100%;
    max-width: 100%;
    padding: 35px 25px;
  }

  .popup .popup-close {
    font-size: 17px;
    line-height: 17px;
  }

  .popup .title {
    font-size: 14px;
    min-height: auto;
    padding-left: 60px;
    margin-bottom: 25px;
    line-height: 24px;
  }

  .popup .title::before {
    left: 0;
  }

  .popup .sub-text {
    font-size: 14px;
    margin: 15px 0;
    line-height: 24px;
  }

  .popup .popup-btn {
    /* width: 80px; */
    height: 30px;
    font-size: 10px;
    padding: 9px 0px 13px 10px;
    margin: 15px auto;
  }

  .popup .popup-btn:hover {
    padding-right: 10px;
    padding-left: 0px;
  }

  .popup .device-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .popup .device-list li {
    padding: 7px 0;
  }

  .popup .device-list li .s-no {
    font-size: 13px;
    width: 5px;
  }

  .popup .device-list li .icon {
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
    margin: 2px 7px 0;
  }

  .popup .device-list li .device {
    font-size: 12px;
    font-weight: normal;
  }

  .popup p.link {
    font-size: 12px;
    margin: 15px auto 0;
  }

  .popup p.link a {
    text-underline-offset: 2px;
  }

  .popup p.link span {
    color: #555555;
    font-size: 18px;
    line-height: 0;
    position: relative;
    top: 3px;
  }
}

/** popup ends **/
</style>
