import mutations from './mutations'
import actions from './actions'

const favouritesModule = {
    namespaced: true,
    state: () => ({
        currentFilter: {
            type: 'articleView',
            recordsFor: 'today'
        },
        articles: [],
        isLoading: false
    }),
    mutations: mutations,
    actions: actions,
    getters: {
        getArticles: (state) => state.articles,
        getCurrentFilter: (state) => state.currentFilter,
        getLoadingState: (state) => state.isLoading
    }
}

export default favouritesModule