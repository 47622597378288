<template>
    <div class="row filters">
        <div class="col-md-7 col-12 d-flex justify-content-md-start justify-content-center p-0">
            <a class="nav-link active" @click.prevent="changeSelectedType" id="articleView">Most viewed</a>
            <a class="nav-link" @click.prevent="changeSelectedType" id="articleDownload">Most downloaded</a>
            <a class="nav-link" @click.prevent="changeSelectedType" id="articleShare">Most shared</a>
        </div>
        <div class="col-md-5 col-12 d-flex justify-content-center justify-content-md-end p-0 align-items-center">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" value="today" v-model="recordsFor" />
                <label class="form-check-label">Today</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" value="last7" v-model="recordsFor" />
                <label class="form-check-label">7 Days</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" value="last30" v-model="recordsFor" />
                <label class="form-check-label">All</label>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    data() {
        return {
            type: "articleView",
            recordsFor: "today"
        }
    },
    watch: {
        getFilterObject(val) {
            this.$logger.debug("Value changed:- ")
            this.$logger.debug(val)
            this.updateCurrentFilter(val)
            this.updateArticles()
        }
    },
    computed: {
        getFilterObject() {
            return {
                type: this.type,
                recordsFor: this.recordsFor
            }
        }
    },
    methods: {
        ...mapActions('activity', [
            'updateCurrentFilter',
            'updateArticles'
        ]),
        changeSelectedType(e) {
            this.$logger.debug(e)
            const filters = document.getElementsByClassName('nav-link')
            for (let i = 0; i < filters.length; i++) {
                const element = filters[i];
                element.classList.remove('active')
            }
            if (e.target.id && e.target.id.trim() !== "") this.type = e.target.id
            e.target.classList.add('active')
            return 'active'
        }
    },
    mounted() {
        this.updateArticles()
    }
}
</script>

<style scoped>
.filters {
    border-bottom: 0.1rem solid #d4d4d4;
    margin: 5px 20px;
}

.filters .nav-link {
    color: #337ab7;
    cursor: pointer;
}

.filters .nav-link:hover {
    color: #256dac;
    background: #eee;
}

.active {
    background: #eee;
}
</style>