
import { createStore } from 'vuex'
import authModule from './auth/index'
import readerModule from './reader/index'
import activityModule from './activity'

const store = createStore({
    modules: {
        auth: authModule,
        reader: readerModule,
        activity: activityModule
    }
})

export default store