function setItem(key, value) {
  sessionStorage.setItem(key, value)
}

function getItem(key) {
  if(sessionStorage[key] !== null){
    return sessionStorage[key];
  }
  else{
    return null;
  }
}

function deleteItem(key) {
  sessionStorage.removeItem(key);
}

export {setItem,getItem,deleteItem};