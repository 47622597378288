import getArticleDetailFromIDs from "./getArticleDetails";

function flattenArticleArray(result) {
    const articles = []
    try {
        for (const date in result) {
            const element = result[date];
            articles.push(...element.map(e => e.Events_EventName))
        }
        articles.sort(sortArticles)
    } catch (err) { console.error(err) }
    return articles
}

function sortArticles(a, b) {
    if (a.nb_events > b.nb_events) return -1
    else if (a.nb_events < b.nb_events) return 1
    return 0
}

function parseArticleRequestResponse(res) {
    if (Array.isArray(res)) {
        res.sort(sortArticles)
        return res.map(el => el.Events_EventName)
    } else if (res instanceof Object) {
        return flattenArticleArray(res)
    } else return res
}

const ep = process.env.VUE_APP_TRENDING_EP

const getActivityArticlesForGivenDate = async (activity, date) => {
    const url = ep + `&date=${date}` + `&segment=eventAction==${activity}`

    const result = await fetch(url)
        .then(res => res.json())
        .then(res => parseArticleRequestResponse(res))
        .catch(err => { console.error(err); return [] })

    const limited_result = result.slice(0, 25); // To avoid Huuuuugeee payloads...
    const articles = await getArticleDetailFromIDs(limited_result)
    return articles
}

export { getActivityArticlesForGivenDate }