<template>
    <div class="p-3" id="free-trial-webview"></div>
</template>



<style scoped>
body {
    background-color: #ffffff !important;
}
</style>