import Logger from "./logToConsole"

const emitIframeEvent = (e) => {
    if (e.detail.type == undefined || e.detail.type.trim() == '') {
        Logger.error("Event type undefined...")
        return
    }

    const ev = new CustomEvent(e.detail.type, e)
    const iframe = document.getElementsByTagName('iframe')[0] ?? null

    if (iframe === null) {
        Logger.error(`emitting ${e.detail.type} failed, iframe not mounted in DOM...`)
        return
    }


    iframe.contentWindow.dispatchEvent(ev)
}

// Triggered only when first page_view event occurs...
const emitUserFavList = (data) => {
    let e = { detail: { type: "user_fav", article_ids: [] } }

    if (data && Array.isArray(data))
        e.detail.article_ids = data
    else
        Logger.error("Emitting user favourite event with empty array!")

    emitIframeEvent(e)
}

const emitFavListResponse = (status) => {
    Logger.debug(`fav action status: ${status}`)
    // fav_action_response
    const e = {
        detail: {
            type: "fav_action_response",
            status: { code: 200 }
        }
    }

    emitIframeEvent(e)
}

const emitUserType = (store) => {
    const userType = store.state.auth.userType
    Logger.debug(`user type:- ${userType}`)
    const e = {
        detail: {
            type: "user_type",
            user_type: userType
        }
    }

    emitIframeEvent(e)
}

export { emitUserFavList, emitFavListResponse, emitUserType }