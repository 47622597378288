<template>
    <div class="col-lg-12 pt-4 pt-lg-0 card-view ">
        <p class="fw-bold">{{ date }}<span class="px-2">{{ editionTitle }}</span></p>
        <a :href="articleURL"><h4>{{ headline }}</h4></a>
        <div class="card-view-image float-end">
            <img width="120" :src="imageURL" class="" alt="" />
        </div>
        <p class="fs-6">{{ content }}</p>
    </div>
    <hr />
</template>
<script>
export default {
    props: {
        date: {
            type: String,
            required: true
        },
        imageURL: {
            type: String,
            required: false
        },
        headline: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: false
        },
        articleURL: {
            type: String,
            required: true
        },
        editionTitle: {
            type: String,
            required: true
        }
    }
}
</script>
<style scoped>
.card-view a {
    color: #337ab7;
    text-decoration: none;
}
@media only screen and (min-width: 900px) {
    .card-view {
        height: 200px;
    }
}
.card-view-image {
    height: 80px;
    width: fit-content;
    overflow: hidden;
    display: block;
}
</style>