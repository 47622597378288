import Logger from "@/utils/logToConsole";

const authModule = {
    namespaced: true,
    state: () => ({
        authStatus: window.tp?.pianoId?.isUserValid() ?? false,
        userType: 'UNKNOWN'
    }),
    mutations: {
        updateAuthStatus: (state) => state.authStatus = window.tp?.pianoId?.isUserValid()
    },
    getters: {
        getAuthState: (state) => state.authStatus,
        getUserType: state => state.userType
    },
    actions: {
        login: (context) => {
            window.tp.pianoId.show({
                loggedIn: (data) => {
                    context.commit('updateAuthStatus')
                    Logger.debug("user ", data.user, " logged in with token", data.token);
                }
            });
        },
        logout: (context) => {
            if (window.tp?.pianoId && window.tp?.pianoId?.getToken() == null) {
                context.commit("updateAuthStatus");
                return;
            }

            try {
                const user_sess_ep = "https://epaperbetath.thehindu.co.in/usersessions";

                const user_sess_req_headers = new Headers();
                user_sess_req_headers.append("Content-Type", "application/json");
                user_sess_req_headers.append(
                "x-client-auth",
                window.tp.pianoId.getToken()
                );

                const user_sess_req_options = {
                method: "POST",
                headers: user_sess_req_headers,
                body: JSON.stringify({
                    type: "piano",
                    session_ids: [
                    {
                        [window.tp.pianoId.getUser().uid]: window.tp.pianoId.getToken(),
                    },
                    ],
                }),
                };

                fetch(user_sess_ep, user_sess_req_options)
                .then((res) => {
                    if (!res.ok) {
                    console.log("user session request status code: ", res.status);
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => window.tp.pianoId.logout());
            } catch (err) {
                console.log("user session request internal error!!", err);
                window.tp.pianoId.logout();
            }

            context.commit("updateAuthStatus");
        }
    }
}

export default authModule;
