<template>
  <the-modal v-if="userStatus == true" modal-id="datepickermodal">
    <div class="modal-content bg-transparent border-0">
      <div class="modal-header p-0">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img src="/icons/close.svg" alt="" srcset="" />
        </button>
      </div>
      <div class="modal-body p-0" id="datepicker"></div>
    </div>
  </the-modal>

  <the-modal v-if="userStatus == true" modal-id="regionlistmodal">
    <div class="modal-content" v-if="region">
      <div class="container">
        <div class="row justify-content-between flex-column">
          <div
            class="col-auto p-3 region m-1"
            @click="changeSelection(reg, 'reg')"
            :class="{ 'region-active': reg.id == region.id }"
            v-for="reg in regionsList"
            :key="reg.id"
          >
            {{ reg.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-content" v-else>
      <div class="container">
        <div class="row justify-content-between p-3">
          No Editions available for {{ date }}
        </div>
      </div>
    </div>
  </the-modal>

  <the-modal v-if="userStatus == true" modal-id="editionlistmodal">
    <div class="modal-content" v-if="edition">
      <div class="container-sm">
        <div class="row justify-content-evenly">
          <a
            class="col-auto p-3 region m-1 text-decoration-none"
            @click="changeSelection(ed, 'edi')"
            :class="{ 'region-active': ed.id == edition.id }"
            v-for="ed in editionsList"
            :key="ed.id"
            href="#"
            :aria-label="
              ed.id === region.id
                ? 'Main Edition'
                : ed.title.replace(/[a-zA-Z]+-/, '')
            "
          >
            <div class="col cover-img">
              <img :src="ed.thumbnail" alt="" />
            </div>
            <div class="col edition-name text-center">
              {{
                ed.id === region.id
                  ? "Main Edition"
                  : ed.title.replace(/[a-zA-Z]+-/, "")
              }}
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="modal-content" v-else>
      <div class="container">
        <div class="row justify-content-between p-3">
          No Supplements available for {{ date }}
        </div>
      </div>
    </div>
  </the-modal>

  <!-- <the-modal modal-id="editionlistmodal">
    <div class="modal-content">
      <li v-for="edition in editionsList" :key="edition">
        {{ edition.title }}
      </li>
    </div>
  </the-modal> -->

  <nav class="navbar navbar-light bg-white m-0" id="nav-bar">
    <div class="container-fluid">
      <a href="/reader" class="navbar-brand">
        <img class="logo" :src="logoPath" alt="" />
        <div v-if="isLoading" class="loader spinner-border" role="status"></div>
      </a>

      <input
        v-if="userStatus == true"
        class="searchbar"
        ref="searchTxt"
        v-model="keywords"
        type="text"
        @keyup.enter="search()"
      />

      <div v-if="userStatus == true" class="searchIcon">
        <img
          src="icons/search_button.png"
          class=""
          width="15"
          @click="search()"
        />
      </div>

      <div class="d-flex menu-items">
        <div class="px-2 dropdown desktop-nav" @click="handleClick">
          <span class="prefix-icon">
            <img src="../../../public/icons/editions.svg" alt="" />
          </span>
          <button
            class="text-decoration-none dropdown-toggle bg-transparent border-0"
            role="button"
            id="editionListDropdown"
            :data-bs-toggle="regionsList ? 'dropdown' : ''"
            aria-expanded="false"
            aria-label="choose edition"
            data-toggle="tooltip"
            title="Choose Edition"
          >
            <span class="menu-text">
              Edition: {{ regionsList ? region.title : "No Editions" }}
            </span>
            <span class="suffix-icon" v-if="regionsList">
              <img src="../../../public/icons/down-arrow.svg" alt="" />
            </span>
          </button>
          <ul class="dropdown-menu" aria-labelledby="editionListDropdown">
            <li v-if="regionsList.length == 0">
              <a class="dropdown-item">No Editions</a>
            </li>
            <li v-for="reg in regionsList" :key="reg.id">
              <a
                @click="changeSelection(reg, 'reg', false)"
                class="dropdown-item"
                href="#"
                :class="{ 'region-active': reg.id == region.id }"
              >
                {{ reg.title }}
              </a>
            </li>
          </ul>
        </div>
        <button
          class="px-2 dropdown desktop-nav bg-transparent border-0"
          aria-label="choose supplement"
          data-bs-toggle="modal"
          data-bs-target="#editionlistmodal"
          id="hideEditionModal"
          data-toggle="tooltip"
          title="Choose Supplement"
        >
          <span class="menu-text">{{
            edition
              ? edition.id === region.id
                ? "Main Edition"
                : edition.title.replace(/[a-zA-Z]+-/, "")
              : "No Supplements"
          }}</span>
          <span class="suffix-icon">
            <img src="../../../public/icons/down-arrow.svg" alt="" />
          </span>
        </button>
        <div
          class="px-2 desktop-nav"
          data-bs-toggle="modal"
          data-bs-target="#datepickermodal"
          id="hideModal"
        >
          <span class="prefix-icon"
            ><img src="../../../public/icons/calendar.svg" alt=""
          /></span>
          <span class="menu-text">{{ formattedDate }}</span>
        </div>
        <div
          class="px-2"
          id="myaccountBtn"
          aria-current="page"
          data-bs-toggle="offcanvas"
          data-bs-target="#rightSideDrawer"
          aria-controls="offcanvasNavbar"
          data-toggle="tooltip"
          title="My Account"
        >
          <span class="prefix-icon">
            <img src="../../../public/icons/profile.svg" alt="" />
          </span>
        </div>
        <button
          class="px-2 bg-transparent border-0"
          aria-label="All Menu Items"
          data-bs-toggle="offcanvas"
          data-bs-target="#leftSideDrawer"
          id="leftDrawer"
          data-toggle="tooltip"
          title="Menu"
        >
          <span class="prefix-icon">
            <img src="../../../public/icons/menu.svg" alt="" />
          </span>
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import getFormattedDate from "@/utils/getFormattedDate";
import TheModal from "./TheModal.vue";
// import { reduceDate } from "@/utils/getIndianTimezoneOffset"
import { setItem, getItem, deleteItem } from "@/utils/setCookie";
import { setMaxDate } from "@/utils/getIndianTimezoneOffset";

export default {
  components: {
    TheModal,
  },
  data() {
    return {
      keywords: "",
      logoPath: process.env.BASE_URL + process.env.VUE_APP_LOGO_FILE,
      modals: [],
    };
  },
  computed: {
    ...mapGetters("reader", {
      date: "getDate",
      region: "getRegion",
      edition: "getEdition",
      regionsList: "getRegionsList",
      editionsList: "getEditionsList",
      isLoading: "getLoadingState",
    }),
    ...mapGetters("auth", {
      userType: "getUserType",
    }),
    combined() {
      return [this.date, this.edition];
    },
    formattedDate() {
      return this.date.trim() !== "" ? getFormattedDate(this.date) : "";
    },
    editionName() {
      const splitEditionTitle = this.edition.title.split("-");
      const testEpaperStringIndex = splitEditionTitle.indexOf("EPaper");
      if (testEpaperStringIndex !== -1)
        splitEditionTitle.splice(testEpaperStringIndex, 1);
      return splitEditionTitle.length == 1
        ? splitEditionTitle[0]
        : splitEditionTitle.join("-");
    },
    userStatus() {
      return window.tp?.pianoId?.isUserValid();
    },
  },
  watch: {
    date(val) {
      this.updateRegionsList();
      this.updateEditionsList();
      this.$logger.debug("date changed: " + val);
    },
    region() {
      this.updateEditionsList();
      window.$("#datepicker").datepicker("option", "minDate", this.getMinDate());
    },
    editionsList() {
      this.updateSelectedEdition();
    },
    combined() {
      this.changeIframeUrl();
    },
  },
  methods: {
    ...mapActions("reader", [
      "changeDate",
      "changeRegion",
      "changeEdition",
      "updateRegionsList",
      "updateEditionsList",
      "updateSelectedEdition",
      "changeIframeUrl",
      "changeLoadingState",
    ]),
    changeSelection(val, type) {
      if (type === "reg") {
        this.changeSelectionAction(type, val, {
          modalId: "regionlistmodal",
          menuId: "#regionName",
        });
        window.$("#datepicker").datepicker("option", "minDate", this.getMinDate());
      }
      else if (type === "edi")
        this.changeSelectionAction(type, val, {
          modalId: "editionlistmodal",
          menuId: "#editionName",
        });
    },
     handleClick() {
      if(this.$store.state.auth.userType !== "SUBCRIBE"){
        window.tp?.push(["setCustomVariable", "edition_click", "true"]);
        window.tp?.experience.execute()
        return
      }
    },
    changeSelectionAction(type, value, config) {
      window.debugger;
      // Update the chosen edition / supplement
      if (type === "reg") {
        this.$logger.debug("Edition change action: ");
        if (this.region.id !== value.id) {
          this.changeLoadingState(true);
          deleteItem("selectededition");
          this.changeRegion(value);
          setItem("selectedregion", JSON.stringify(value));
        }
      } else {
        this.$logger.debug("Supplement change action: ");
        if (this.edition.id !== value.id) {
          this.changeLoadingState(true);
          this.changeEdition(value);
          setItem("selectededition", JSON.stringify(value), 0);
        }
      }
      this.$logger.debug(value);

      // Close the corresponding Modal
      this.modals[config.modalId].hide();
      window.$(config.menuId).html(value.title);
    },
    search() {
      if (this.keywords.trim() !== "")
        this.$router.push(`/search?q=${this.keywords}`);
    },
    registerModals() {
      const modalIds = ["regionlistmodal", "editionlistmodal"];

      if (window.tp?.pianoId?.isUserValid()) {
        modalIds.forEach((modalId) => {
          const modalElement = document.getElementById(modalId);
          const modalObject = new window.bootstrap.Modal(modalElement);
          this.modals[modalId] = modalObject;
        });
      }
    },
    getMinDate(){
    // Restrict Achieve date for international editions
      let dateVal = null;
      let updatedregion = undefined;
      const internationaledition = () => updatedregion?.id == "th_international";

      if (getItem('selectedregion') != undefined && getItem('selectedregion') != null) {
        updatedregion = JSON.parse(getItem('selectedregion'));
        if (internationaledition()) dateVal = new Date(2023, 6, 1);
        if (this.userType === "TRIAL") dateVal = 0;
      }
      return dateVal;
    }
  },
  mounted() {
    this.registerModals();
    // if (this.userStatus === false) {
    // get elements with ids editionListDropdown and hideEditionModal
    const editionListDropdown = document.getElementById("editionListDropdown");
    const hideEditionModal = document.getElementById("hideEditionModal");
    const dateSelectionModal = document.getElementById("hideModal");
    const profileBtn = document.getElementById("myaccountBtn");

    [editionListDropdown, hideEditionModal, profileBtn].forEach((element) => {
      if (window.tp?.pianoId?.isUserValid() === false) {
        element.parentElement.classList.remove("dropdown");
        element.removeAttribute("data-bs-toggle");
      }
    });

    [
      editionListDropdown,
      hideEditionModal,
      dateSelectionModal,
      profileBtn,
    ].forEach((element) => {
      element.onclick = (e) => {
        if (window.tp?.pianoId?.isUserValid() === false) {
          e.preventDefault();
          this.$store.dispatch("auth/login");
        }
      };
    });
    // }
    var updateddate = getItem("selecteddate");
    window.$("#datepicker").datepicker({
      defaultDate:
        updateddate !== null
          ? updateddate
          : new Date(
              new Date().toLocaleString("en-US", { timeZone: "Asia/Calcutta" })
            ),
      changeMonth: true,
      changeYear: true,
      dateFormat: "yy-mm-dd",
      maxDate: setMaxDate() === 0 ? +0 : setMaxDate() === 1 ? +1 : -1,
      minDate: this.getMinDate(),
      onSelect: (d) => {
        if(this.$store.state.auth.userType !== "SUBCRIBE"){
          window.tp?.push(["setCustomVariable", "date_click", "true"]);
          window.tp?.experience.execute()
          window.$("#hideModal").click();
          return
        }
        setItem("selecteddate", d);
        // deleteItem('selectedregion')
        deleteItem("selectededition");
        // Trigger date change event
        this.changeDate(d);
        // Front-end date format
        window.$("#selectedDate").html(getFormattedDate(d));
        // Close modal
        window.$("#hideModal").click();
      },
    });
  },
};
</script>

<style scoped>
.loader {
  width: 20px;
  height: 20px;
  border-width: 0.2rem;
  margin-left: 10px;
}
.logo {
  width: 100%;
}

.prefix-icon,
.suffix-icon {
  margin-right: 5px;
}

.prefix-icon img {
  width: 20px;
}

.suffix-icon img {
  width: 9px;
}

.menu-text {
  margin-right: 5px;
  font-size: 15px;
  font-weight: normal;
  color: black;
}

.nav-link {
  color: black;
}

.menu-items > div {
  cursor: pointer;
  text-align: center;
}

.menu-items > div::after {
  content: "";
  display: inline-block;
  margin-bottom: -0.5em;
  height: 1.5em;
  width: 1px;
  background-color: lightgrey;
  margin-left: 0.5em;
}

.menu-items > div:first-child::after,
.menu-items > div:last-child::after {
  content: "";
  background-color: transparent;
}

.dropdown-toggle::after {
  content: "";
  display: inline-block;
  margin: 0;
  transform: scale(0.7);
  vertical-align: middle;
  border: 0;
}
.region {
  text-align: left;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #000;
  cursor: pointer;
}

.region-active {
  color: green;
}

.region-active > .cover-img img {
  border: 1px solid green;
}

.btn-close {
  background: none;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 50%;
  padding: 10px;
  opacity: 1;
}

.btn-close > img {
  width: 10px;
  margin-bottom: 5px;
}
.searchbar {
  position: absolute;
  right: 610px;
}
.searchIcon {
  position: absolute;
  right: 620px;
}
.exclamation {
  position: absolute;
  right: 853px;
}
@media only screen and (max-width: 576px) {
  .desktop-nav {
    display: none;
  }
  .exclamation {
    position: absolute;
    right: 115px;
    opacity: 0.5;
  }
}

.cover-img {
  max-width: 200px;
  border: 1px solid #eee;
}

.cover-img img {
  width: 100%;
  max-height: 259.84px;
}

.edition-name {
  margin-top: 10px;
  font-size: 14px;
}

@media only screen and (max-width: 900px) {
  .desktop-nav {
    display: none;
  }
  .navbar-brand {
    width: 50%;
  }
  .logo {
    width: 100%;
  }
  .searchbar {
    display: none;
  }
  .searchIcon {
    display: none;
  }
  .exclamation {
    position: absolute;
    right: 115px;
    opacity: 0.5;
  }
}

/* @media only screen and (max-width: 900px) {
  .navbar-brand {
    width: 50%;
  }
} */
</style>
