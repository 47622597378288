import Article from "@/store/model/article";
import Logger from "@/utils/logToConsole"

const articleEp = process.env.VUE_APP_ARTICLE_DETAILS_EP

async function getArticleDetailFromIDs(articleIds) {
    const result = await fetch(articleEp, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "ids": articleIds, "pub": process.env.VUE_APP_PUB }),
        redirect: 'follow'
    })
        .then(res => res.json())
        .then(res => {
            const articles = res.map(el => Article.fromJSON(el))
            return articles
        })
        .catch(err => { Logger.error(err); return [] })
    console.log('articleid details',JSON.stringify(result))    
    return result
}

export default getArticleDetailFromIDs