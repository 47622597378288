<template>
    <the-header></the-header>
    <!-- 🤔 Don't know what heading to give... -->
    <page-info-nav heading="" icon="fa fa-chevron-left"></page-info-nav>
    <iframe class="share-iframe" frameborder="0" :src="shareUrl"></iframe>
</template>

<script>
import TheHeader from '@/components/commonHeader.vue'
import PageInfoNav from "@/components/PageInfoNav"

export default {
    props: {
        shareUrl: String
    },
    components: {
        TheHeader,
        PageInfoNav
    },
    methods: {
        checkRedirectUrl() {
            if (typeof this.shareUrl !== 'string') {
                this.$logger.debug("share url undefined..");
                location.href = "/reader"
            }
        },
        setIframeHeight() {
            const pageInfoNavHeight = document.getElementById('page-info-nav').clientHeight
            const commonHeaderHeight = document.getElementById('common-header').clientHeight
            const offset = 6
            const requiredHeight = innerHeight - pageInfoNavHeight - commonHeaderHeight - offset
            document.querySelector('iframe').style.height = `${requiredHeight}px`
        },
    },
    mounted() {
        this.checkRedirectUrl()
        this.setIframeHeight()
    }
}
</script>

<style>
.share-iframe {
    width: 100%;
    overflow: hidden;
    height: 98vh;
}
</style>
