import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Logger from './utils/logToConsole'

const app = createApp(App)
app.config.globalProperties.$logger = Logger

app.use(router)
app.use(store)
app.mount('#app')
