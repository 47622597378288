function reduceDate() {
    const current_date = new Date()
    const current_date_in_indian_timezone = new Date(
        current_date.toLocaleString('en-US', { timeZone: 'Asia/Calcutta' })
    )
    if (current_date_in_indian_timezone.getHours() < 5) return true
    return false
}

const setMaxDate = () => {
    const current_date = new Date()
    const current_date_in_indian_timezone = new Date(
        current_date.toLocaleString('en-US', { timeZone: 'Asia/Calcutta' })
    )
    // if current date is > than 5am in indian timezone, then set max date to +1 day
    let maxDate = 0
    if (current_date.getDate() > current_date_in_indian_timezone.getDate()) {
        // decrease date by 1 day
        maxDate = +1

    }
    if(current_date.getDate() < current_date_in_indian_timezone.getDate()){
        if(current_date_in_indian_timezone.getHours() < 5){
            //no change
            maxDate = 0
        }
        else {
            // increase date by 1 day
            maxDate = +1
        }
    }
    if(current_date.getDate() === current_date_in_indian_timezone.getDate()){
        if(current_date_in_indian_timezone.getHours() < 5){
            // decrease date by 1 day
            maxDate = -1
        }
        else {
            //no change
            maxDate = 0
        }
    }
    console.log("maxDate",maxDate)
    return maxDate
}

function getTodaysDateWithOffset() {
    var d = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Calcutta' }));
    if (reduceDate()) d.setDate(d.getDate() - 1);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${ye}-${mo}-${da}`
}

export { reduceDate, getTodaysDateWithOffset ,setMaxDate }