import Logger from "@/utils/logToConsole"

const baseUrl = process.env.VUE_APP_WS_API_URL

const getRegionListUrl = (date) => `${baseUrl}?json=true&fromDate=${date}&toDate=${date}&skipSections=true&os=web&excludePublications=*-*`

export default (date, options={}) => {
    let url = getRegionListUrl(date)
    Logger.debug(`getRegionListUrl: ${url}`)
    return fetch(url, options)
}