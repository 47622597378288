export default {
    changeLoadingState: (state, loadingState) => state.loading = loadingState,
    changeDate: (state, date) => state.date = date,
    changeRegion: (state, region) => state.region = region,
    changeEdition: (state, edition) => state.edition = edition,
    changeRegionList: (state, regionsList) => state.regionsList = regionsList,
    changeEditionsList: (state, editionsList) => state.editionsList = editionsList,
    changeIframeUrl: (state, iframeUrl) => state.iframeUrl = iframeUrl,
    changeArticleViewUserEventFiredStatus: (state) => state.isArticleViewUserEventFired = true,
    resetArticleViewUserEventFiredStatus: (state) => state.isArticleViewUserEventFired = false,
}