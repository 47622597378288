<template>
    <button :class="classes" @click="clickAction">
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        deviceType: {
            type: String,
            required: true
        },
        classNames: {
            type: Array,
            required: true
        },
        clickAction: {
            type: Function,
            required: false
        }
    },
    computed: {
        classes() {
            if (this.deviceType == 'mobile') return ['btn', ...this.classNames]
            return ['base-btn', ...this.classNames]
        }
    }
}
</script>

<style scoped>
.btn-signup {
    transition: ease-out 0.3s;
    box-shadow: inset 0 0 0 0 #C00;
    margin-right: 0;
}

.btn-subscribe, .btn-signup, .btn-account, .btn-premium, .go-to-search, .btn-free-trial {
    background: #ffffff;
    border-radius: 3px;
    border: 1px solid #C00;
    color: #C00;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    width: 147px;
    padding: 9px 0 6px 0;
    transition: ease-out 0.3s;
    margin-right: 5px;
}
</style>