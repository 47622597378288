<template>
    <div class="accordion-item">
        <h2 class="accordion-header" :id="id">
            <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#' + 'target-' + id"
                aria-expanded="false"
                :aria-controls="'target-' + id"
            >{{ heading }}</button>
        </h2>
        <div
            :id="'target-' + id"
            class="accordion-collapse collapse"
            :aria-labelledby="id"
            :data-bs-parent="'#' + parentId"
        >
            <div class="accordion-body">
                <div class="content">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        id: {
            type: String,
            required: true
        },
        parentId: {
            type: String,
            required: true
        },
        heading: {
            type: String,
            required: true
        }
    }
}

</script>

<style scoped>
.content {
    font-size: 14px;
}
.accordion-header button {
    font-weight: bold;
}
.accordion-button:not(.collapsed) {
    color: black;
    background-color: white;
}
</style>