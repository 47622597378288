function cleanArticleUrl() {
  const params_to_remove = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];

  const url_obj = new URL(`${window.location.href}`);
  url_obj.searchParams.forEach((v, k) => {
    if (params_to_remove.includes(k)) url_obj.searchParams.delete(k);
  });

  return url_obj.toString();
}

function getHeaders() {
  return {
    Accept: "application/json, text/plain, */*",
    Authorization: "Basic " + process.env.VUE_APP_GNI_AUTH,
    "Content-Type": "application/json",
  };
}

export { cleanArticleUrl, getHeaders };
