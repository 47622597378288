import { getActivityArticlesForGivenDate } from "@/request-handlers/trending";
import getTodaysDate from "@/utils/getTodaysDate";
import Logger from "@/utils/logToConsole";

export default {
    updateCurrentFilter: (context, filter) => context.commit('updateFilter', filter),
    updateArticles: async (context) => {
        context.commit('updateLoadingState', true)

        let date = context.state.currentFilter.recordsFor == 'today' ?
            getTodaysDate() : context.state.currentFilter.recordsFor

        context.state.articles =
            await getActivityArticlesForGivenDate(context.state.currentFilter.type, date)
                .then(res => {
                    Logger.debug(res);
                    context.commit('updateLoadingState', false)
                    return res
                })
                .catch(err => { 
                    Logger.error(err)
                    context.commit('updateLoadingState', false)
                    return [] 
                })
    }
}