<template>
  <side-bar-right @route-event="routeTo($event)" />
  <side-bar-left :menu-items="leftSidebarMenus" />
  <the-header></the-header>
  <div ref="readerIframe" v-if="isIframeUrlNotNull">
    <iframe
      id="reader-iframe"
      :src="iframeUrl"
      frameborder="0"
      @load="registerIframeOnLoadActions"
    ></iframe>
  </div>
  <div class="p-3 text-center" v-else style="min-height: 75vh">
    <p v-if="editionsList.length == 0" class="mt-5">
      No issues available {{ region.title }} for this date: {{ date }}
    </p>
  </div>
  <div v-if="isIframeUrlNotNull">
    <div class="btnPos" id="open_in_app">
      <button class="ios-button">
        <div>
          <span onclick="window.location.href='https://epaper.page.link/Tbeh';">
            <img
              v-if="isIOS"
              class="apple"
              :src="publicPath + 'icons/apple.svg'"
            />
            <img
              v-if="isAndroid"
              class="apple"
              :src="publicPath + 'icons/google-play.svg'"
            />
            Open in App</span
          >
          <img
            @click="closeButton"
            class="closeWhite"
            :src="publicPath + 'icons/closeWhite.svg'"
          />
        </div>
      </button>
    </div>
    <!-- <div v-if="showCloseButton" class="btnPos">
      <button v-if="isAndroid" class="ios-button">
        <div>
          <span onclick="window.location.href='https://epaper.page.link/Tbeh';">
            <img class="apple" :src="publicPath + 'icons/google-play.svg'" />
            Open in App</span>
          <img @click="closeButton" class="closeWhite" :src="publicPath + 'icons/closeWhite.svg'" />
        </div>
      </button>
    </div> -->
  </div>
  <div id="curtain-raiser"></div>
  <session-logout-prompt
    :device_info="devices_info"
    v-show="isSessionLimitExceeded"
  ></session-logout-prompt>
  <!-- <google-sign-in></google-sign-in> -->
  <!-- <footer-page></footer-page> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import TheHeader from "@/components/ReaderPage/MainNavBar.vue";
import SidebarProfile from "@/components/ReaderPage/sidebarMenus/SidebarProfile.vue";
import SidebarMenu from "@/components/ReaderPage/sidebarMenus/SidebarMenu.vue";
// import FooterPage from "@/components/RXLoginPage/FooterPage.vue";
import SessionLogoutPrompt from "@/components/SessionLogoutPrompt.vue";
import Events from "@/utils/events";
import CallBacks from "@/utils/eventCallBacks";

import LeftSideBarMenuItems from "@/utils/leftSidebarMenuItems";

export default {
  name: "ReaderPage",
  components: {
    SideBarRight: SidebarProfile,
    TheHeader,
    SideBarLeft: SidebarMenu,
    // FooterPage,
    SessionLogoutPrompt,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      leftSidebarMenus: LeftSideBarMenuItems,
      ua: navigator.userAgent,
      isIOS: false,
      isAndroid: false,
      showCloseButton: true,
      storageKey: "closeButtonTimestamp",
    };
  },
  computed: {
    ...mapGetters("reader", {
      isLoading: "getLoadingState",
      iframeUrl: "getIframeUrl",
      date: "getDate",
      region: "getRegion",
      regionsList: "getRegionsList",
      editionsList: "getEditionsList",
      isSessionLimitExceeded: "getSessionLimitExceeded",
      devices_info: "getSessionDevicesInfo",
    }),
    isIframeUrlNotNull() {
      return this.iframeUrl !== "null";
    },
  },
  watch: {
    iframeUrl() {
      const oldIframe = this.$refs.readerIframe?.firstElementChild ?? null;
      if (oldIframe != null) {
        const newIframe = window.document.createElement("iframe");
        newIframe.setAttribute("src", this.iframeUrl);
        newIframe.setAttribute("id", "reader-iframe");
        oldIframe.remove();

        this.$refs.readerIframe.appendChild(newIframe);
        this.registerIframeOnLoadActions(newIframe);
      }
    },
  },
  methods: {
    ...mapActions("reader", ["setInitialIframeUrl", "changeLoadingState"]),
    routeTo(val) {
      window.$("#myaccountBtn").click();
      this.$router.push(`/${val}`);
    },
    registerIframeOnLoadActions(ev) {
      this.$logger.debug("Iframe load event");
      this.setIframeHeight();
      console.log("iframe height calculated!!");
      try {
        Events.forEach((eventID) =>
          this._registerEventListener(ev.target ?? ev, eventID)
        );
      } catch (error) {
        this.$logger.error("Error from eventListeners: ", error);
        console.log(
          "!--------------iframe ev-listener error-----------------!"
        );
        console.log(error);
        this.changeLoadingState(false);
      }
    },
    /**
     * Pre-calculate iFrame height, to
     *
     *      1. Avoid text blurring while viewing PDF.
     *      2. To avoid unwanted scrolling issues.
     */
    setIframeHeight() {
      const navbarHeight = document.getElementById("nav-bar").clientHeight;
      const offset = 6;
      const requiredHeight = innerHeight - navbarHeight - offset;
      document.getElementById(
        "reader-iframe"
      ).style.height = `${requiredHeight}px`;
    },
    closeButton() {
      this.showCloseButton = false;
      document.getElementById("open_in_app").style.display = "none";
      const timestamp = new Date().getTime();
      localStorage.setItem(this.storageKey, timestamp.toString());
    },
    /**
     * Register eventListeners for all events in the [Events] array
     *
     * @param HTMLElement target element to attach the listener
     * @param String eventId to listen for
     */
    _registerEventListener(target, eventID) {
      // Register event listener for the [eventID]
      target.contentWindow.addEventListener(eventID, (eventDetails) => {
        // Log recieved event details...
        this.$logger.debug(`${eventDetails.type} event occured...`);
        this.$logger.debug(eventDetails);
        // Register the respective callback for that [eventID]
        const cb = CallBacks[eventID];
        // Execute the callback with [store] and [eventDetails] as params
        if (cb) cb(this.$store, eventDetails);
        // GTM Event tracking
        CallBacks["common_event_tracking"](eventDetails);
      });
    },
  },
  created() {
    console.log("this.btn_State", this.btn_State);
    this.$logger.info("ReaderPage component created..");
    this.setInitialIframeUrl();
    const lastCloseTimestamp = localStorage.getItem(this.storageKey);
    if (lastCloseTimestamp) {
      const timeDiff = new Date().getTime() - parseInt(lastCloseTimestamp);
      if (timeDiff < 5 * 60 * 1000) {
        // this.showCloseButton = false;
        // this.btnState = this.btnState == 'showBtn' ? 'hideBtn' : 'showBtn';
      }
    }
    const userAgent = navigator.userAgent;
    console.log({ userAgent });

    // Open in app ios
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i)
    ) {
      this.isIOS = true;
      console.log("ios devide");
    }
    // Open in app Android
    if (userAgent.match(/Android/i)) {
      this.isAndroid = true;
      console.log("android devide");
    }
    // this.isIOS = true;
    // this.isAndroid = true;
  },
  mounted() {
    this.setIframeHeight();
  },
};
</script>

<style>
/* .reader {
      width: 100%;
      height: 100vh;
      background-color: lightgray;
      position: relative;
      overflow: hidden;
  } */
#reader-iframe {
  width: 100%;
  overflow: hidden;
}

.loader {
  height: 90vh;
}

.showBtn {
  display: block;
}

.hideBtn {
  display: none;
}

.ios-button {
  position: relative;
  background-color: black;
  border-radius: 15px;
  border: none;
  color: white;
  padding: 5px;
  width: 149px;
  text-align: center;
  font-size: 12px;
}

.apple {
  padding-right: 5px;
  width: 15px;
  height: 19px;
}

.closeWhite {
  padding-left: 5px;
}

.btnPos {
  display: block;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#open_in_app {
  display: none;
}
</style>
