<template>
    <div class="modal fade" :id="modalId" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered justify-content-center">
            <div class="modal-dialog">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        modalId: {
            type: String,
            required: true
        }
    }
}

</script>