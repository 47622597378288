<template>
	<the-header></the-header>
	<TopBanner></TopBanner>
    <SubscribeBox></SubscribeBox>
    <PianoSubscription></PianoSubscription>
    <FooterPage></FooterPage>
</template>

<script>
import TheHeader from '@/components/RXLoginPage/HeaderPage.vue'
import TopBanner from '@/components/RXLoginPage/TopBanner.vue'
import SubscribeBox from '@/components/RXLoginPage/SubscribeBox.vue'
import PianoSubscription from '@/components/RXLoginPage/PianoSubscription.vue'
import FooterPage from '@/components/RXLoginPage/FooterPage.vue'


export default {
	components: {
		TheHeader,
        TopBanner,
        SubscribeBox,
        PianoSubscription,
        FooterPage
    },
	mounted() {
		window.tp?.push(["init", () => {
			if (window.tp.pianoId?.isUserValid()) {
				this.$router.push('/reader')
			}
		}]);
	}
}
</script>
