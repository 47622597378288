const LeftSideBarMenuItems = [
    {
        title: "SEARCH",
        href: "search",
        routeEvent: true,
        newTab: false
    },
    {
        title: "LEARNING CORNER",
        href: "https://learningcorner.epaper.thehindu.com/?utm_source=site&utm_medium=menu",
        routeEvent: false,
        newTab: true
    },
    {
        title: "SUBSCRIPTION",
        href: "subscribe",
        routeEvent: true,
        newTab: false
    },
    {
        title: "MOST POPULAR ARTICLES",
        href: "activity",
        routeEvent: true,
        newTab: false
    },
    {
        title: "CONTACT US",
        href: "contact",
        routeEvent: true,
        newTab: false
    },
    {
        title: "FAQ",
        href: "faq",
        routeEvent: true,
        newTab: false
    },
    {
        title: "TERMS AND CONDITIONS",
        href: "https://www.thehindugroup.com/termsofuse.html",
        routeEvent: true,
        newTab: true
    },
    {
        title: "PRIVACY POLICY",
        href: "https://www.thehindugroup.com/privacy.html",
        routeEvent: true,
        newTab: true
    }
]

export default LeftSideBarMenuItems 