<template>
    <the-header></the-header>
    <div id="subscription"></div>
</template>

<script>
import TheHeader from '@/components/commonHeader.vue'

export default {
    components: {
        TheHeader
    },
    mounted() {
        window.tp?.push(["init", () => {
            window.tp?.experience.execute()
        }]);
    }
}
</script>