import { createRouter, createWebHistory } from 'vue-router'
import { AppGuard, WebViewGuard } from './guards'

// import Login from '@/pages/LoginPage'
import Reader from '@/pages/ReaderPage'
import MyAccount from '@/pages/MyAccountPage'
import Subscribe from '@/pages/SubscriptionPage'
import Search from '@/pages/SearchPage'
import MostPopular from '@/pages/MostPopularPage'
import Favourites from '@/pages/FavouritesPage'
import ArticleShare from '@/pages/SingleArticlePage'
import Contact from '@/pages/ContactPage'
import Faq from '@/pages/FAQPage'
import TermsAndCondition from '@/pages/TermsAndConditionsPage'
import EditionCheck from '@/pages/EditionCheckPage'
import AppWebView from '@/pages/appwebview/AppWebview'
import Registration from '@/pages/RegistrationPage'

// !!! appwebview pages !!!
import MyAccountWebView from '@/pages/appwebview/pages/MyAccountPage'
import SubscriptionPage from '@/pages/appwebview/pages/SubscriptionPage'
import FreeTrail from '@/pages/appwebview/pages/FreeTrailPage'
import FaqPage from '@/pages/appwebview/pages/FAQPage'
import ContactUs from '@/pages/appwebview/pages/ContactPage'
import TermsAndConditionPage from '@/pages/appwebview/pages/TermsAndConditionsPage'
import AndroidWebView from '@/pages/appwebview/pages/AndroidWebView'
import NotFound from '@/pages/NotFoundPage.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', redirect: '/reader', meta: { title: "The Hindu ePaper | Daily News and Current Affairs", auth: false } },
        { path: '/reader', component: Reader, meta: { title: "The Hindu ePaper | Daily News and Current Affairs", auth: false } },
        { path: '/login', redirect: '/reader', meta: { title: "The Hindu ePaper | Daily News and Current Affairsu", auth: false } },
        { path: '/registration', component: Registration, meta: { title: "The Hindu ePaper | Daily News and Current Affairs", auth: false } },
        { path: '/myaccount', component: MyAccount, meta: { title: "My Account - The Hindu", auth: true } },
        { path: '/subscribe', component: Subscribe, meta: { title: "Subscribe - The Hindu", auth: false } },
        { path: '/dsubscription', component: Subscribe, meta: { title: "Subscribe - The Hindu", auth: false } },
        { path: '/Home/dsubscription', component: Subscribe, meta: { title: "Subscribe - The Hindu", auth: false } },
        { path: '/Home/DirectSubscription', component: Subscribe, meta: { title: "Subscribe - The Hindu", auth: false } },
        { path: '/search', component: Search, props: route => ({ query: route.query.q }), meta: { auth: true } },
        { path: '/activity', component: MostPopular, meta: { auth: true } },
        { path: '/favourites', component: Favourites, meta: { auth: true } },
        { path: '/articleshare', component: ArticleShare, props: route => ({ shareUrl: route.query.articleurl }), meta: { auth: true } },
        { path: '/contact', component: Contact, meta: { auth: false } },
        { path: '/faq', component: Faq, meta: { auth: false } },
        { path: '/terms-and-condition', component: TermsAndCondition, meta: { auth: false } },
        { path: '/edition-check', component: EditionCheck, meta: { auth: true } },
        {
            path: '/appwebview',
            component: AppWebView,
            beforeEnter: WebViewGuard,
            children: [
                { path: 'freetrial', component: FreeTrail },
                { path: 'myaccount', component: MyAccountWebView },
                { path: 'subscription', component: SubscriptionPage },
                { path: 'android/webview.html', component: AndroidWebView },
                { path: 'faq', component: FaqPage },
                { path: 'contact', component: ContactUs },
                { path: 'terms-and-condition', component: TermsAndConditionPage },
            ],
            meta: { auth: false }
        },
        { path: '/:pathMatch(.*)*', component: NotFound, meta: { auth: false } }
    ]
})

router.beforeEach(AppGuard)

export default router