<template>
    <div class="p-3" id="my-account-webview"></div>
</template>



<style scoped>
body {
    background-color: #ffffff !important;
}
</style>