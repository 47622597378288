import Article from "@/store/model/article"
import getTodaysDate from "@/utils/getTodaysDate"

const solrDomain = process.env.VUE_APP_SOLR_URL

async function SearchRequestHandler(
    searchText = '',
    fromDate = getTodaysDate(),
    toDate = getTodaysDate(),
    start = null,
    edition = null
) {
    if (searchText.trim() !== '') {
        const queryURL = createURL(searchText, fromDate, toDate, start, edition)
        const result = await fetch(queryURL).then(res => {
            if (!res.ok) return null
            return res.json()
        })
        if (result && Array.isArray(result.response.docs)) {
            return result.response.docs
            .filter(article => article.articleheadline && article.articleheadline[0].trim() !== "")
            .map(article => Article.fromJSON(article))
        }
    }

    return null
}

const createURL = (searchText, fromDate, toDate, start = null, edition = null) => {
    const query = `?fq=os:web&fq=${encodeURIComponent(`(CONTENT:${searchText} OR articleheadline:${searchText})`)}`
    const dateFilter = `&q=issue_date:[${encodeURIComponent(`${fromDate}T00:00:00Z TO ${toDate}T23:59:59Z`)}]`
    const organizationFilter = '&fq=organization_id:' + process.env.VUE_APP_PUB
    const publication = edition ? `&fq=publication_id:${edition}` : ''
    const rows = '&rows=100'
    const queryURL = solrDomain + query + dateFilter + organizationFilter + publication + rows

    return start ? queryURL + `&start=${start}` : queryURL
}

export default SearchRequestHandler