<template>
<section class="top-banner">
    <div class="container">
        <div class="todays-paper-banner">
            <div class="todays-paper">
                <div class="computer">
                    <img src="../../../public/icons/laptop.png" alt="Device">
                </div>
                <div class="paper">
                    <img :src="image" alt="Todays Paper"/>
                </div>
            </div>
            <div class="text-part">
                <h1>Read the digital replica of our daily newspaper</h1>
                <button id="trial-btn" class="basic-btn black-btn"><img src="../../../public/icons/h-circle-white.svg">&nbsp;&nbsp;<span>Try The Hindu e-Paper</span></button>
                <p class="terms">*subscription to be purchased separately</p>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import paper1 from '../../../public/icons/paper1.png'
export default {
  data() {
    return {
      image: paper1
    }
  },
  mounted() {
    this.todayspaperImg();
  },
  methods: {
    todayspaperImg(){
      fetch(process.env.VUE_APP_TODAYS_PAPER_FRONT_IMG).then(function(response) {
        return response.text()
      })
      .then(data => { 
        this.image = this.createElementFromHTML(data).querySelector("img").getAttribute("data-src-template")
      })
      .catch(function(error) {
        console.log(error);
      });
    },
    createElementFromHTML(htmlString) {
      var div = document.createElement('div');
      div.innerHTML = htmlString.trim();
      return div.firstChild;
    }
  }
}
</script>

<style scoped>
@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Faustina:wght@300;400;500;600;700;800&display=swap");
* {
  font-family: "Merriweather", serif;
  font-weight: normal;
}

.black-text {
  color: #000000 !important;
}

.base-text {
  color: #B00020 !important;
}

section {
  display: block;
  clear: both;
  width: 100%;
  margin: 60px auto;
}
section.gray-bg {
  padding: 60px 0;
  background: #f1f1f1;
}

@media screen and (max-width: 570px) {
  section {
    margin: 30px auto;
  }
  section.gray-bg {
    padding: 30px 0;
  }
}
@media screen and (max-width: 1000px) and (min-width: 571px) {
  section {
    margin: 45px auto;
  }
  section.gray-bg {
    padding: 45px 0;
  }
}
.container {
  max-width: 1300px;
}

a, button {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
a:hover, button:hover {
  text-decoration: underline;
  color: inherit;
}
a:hover .ad::after, button:hover .ad::after {
  text-decoration: none;
}

/* Basic Buttons starts */
.basic-btn {
  width: 100%;
  height: 60px;
  border: 1px solid #000000;
  border-radius: 3px;
  font-family: "Merriweather Sans", sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  color: #000000;
  padding: 0 20px;
  background: none;
  margin: 10px 0;
  position: relative;
  transition: all 0.3s;
}
.basic-btn span {
  font-family: inherit;
  position: relative;
  top: 1px;
}
.basic-btn:focus {
  box-shadow: none;
  outline: none;
}
.basic-btn:after {
  content: "→";
  position: relative;
  opacity: 0;
  top: 0px;
  right: 20%;
  transition: 0.3s;
}
.basic-btn:hover {
  padding-right: 60px;
  padding-left: 30px;
  color: #000000;
  text-decoration: none;
  outline: none;
}
.basic-btn:hover::after {
  opacity: 1;
  right: -8px;
}
.basic-btn .left-icon {
  position: relative;
  margin-right: 5px;
  top: -2px;
}

.black-btn {
  background: #000;
  color: #ffffff;
  width: max-content;
  padding-left: 25px;
  padding-right: 15px;
  margin: 0;
  display: block;
  text-transform: none;
}
.black-btn img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.black-btn:hover {
  padding-right: 25px;
  padding-left: 15px;
  color: #ffffff;
}

.red-btn {
  background: #B00020;
  color: #ffffff;
  width: max-content;
  border-color: #B00020;
  padding-left: 25px;
  padding-right: 15px;
  margin: 0;
  display: block;
  text-transform: none;
}
.red-btn img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.red-btn:hover {
  padding-right: 25px;
  padding-left: 15px;
  color: #ffffff;
}

@media screen and (max-width: 570px) {
  .basic-btn {
    height: 46px;
    font-size: 13px;
  }
  .basic-btn .left-icon {
    width: 20px;
    height: auto;
  }
}
@media screen and (max-width: 1000px) and (min-width: 571px) {
  .basic-btn {
    height: 50px;
    font-size: 14px;
    padding: 0 20px;
    margin: 10px 0;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .basic-btn {
      width: 100%;
      height: 60px;
      border: 1px solid #000000;
      border-radius: 3px;
      font-family: "Merriweather Sans", sans-serif;
      text-transform: uppercase;
      font-size: 18px;
      color: #000000;
      padding: 0 20px;
      background: none;
      margin: 10px 0;
      position: relative;
      transition: all 0.3s;
    }
    .basic-btn span {
      font-family: inherit;
    }
    .basic-btn:focus {
      box-shadow: none;
      outline: none;
    }
    .basic-btn:after {
      content: "→";
      position: relative;
      opacity: 0;
      top: 3px;
      right: 20%;
      transition: 0.3s;
    }
    .basic-btn:hover {
      padding-right: 60px;
      padding-left: 30px;
      color: #000000;
      text-decoration: none;
      outline: none;
    }
    .basic-btn:hover::after {
      opacity: 1;
      top: 3px;
      right: -8px;
    }
    .basic-btn .left-icon {
      position: relative;
      margin-right: 5px;
      top: -2px;
    }
  }
}
/* Basic Button ends */
/* Todays Paper Banner starts **/
.top-banner {
  background: #f1f1f1;
  margin: 0 auto;
  padding: 40px 0;
}
.top-banner .container {
  width: 100%;
  max-width: 100%;
}
.top-banner .todays-paper-banner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-banner .todays-paper-banner .todays-paper {
  display: block;
  position: relative;
}
.top-banner .todays-paper-banner .todays-paper .computer {
  width: 900px;
}
.top-banner .todays-paper-banner .todays-paper .computer img {
  width: 100%;
}
.top-banner .todays-paper-banner .paper {
  position: absolute;
  top: 60px;
  left: 136px;
  width: 630px;
  --bs-aspect-ratio: calc(440 / 700 * 100%);
}
.top-banner .todays-paper-banner .paper::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.top-banner .todays-paper-banner .paper > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.top-banner .todays-paper-banner .paper img {
  width: 100%;
  -o-object-fit: inherit;
  object-fit: inherit;
  object-position: top;
  height: 100%;
}
.top-banner .todays-paper-banner .text-part {
  padding-right: 20px;
  display: block;
  text-align: left;
  max-width: 600px;
}
.top-banner .todays-paper-banner .text-part h1 {
  font-family: "Playfair Display", serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 44px;
  color: #000000;
  margin: 0 0 20px;
}
.top-banner .todays-paper-banner .text-part p {
  font-family: "Merriweather", serif;
  font-size: 12px;
  margin: 15px 0 0;
}

@media screen and (max-width: 1180px) and (min-width: 571px) {
  .top-banner {
    background: #f1f1f1;
    margin: 0 auto;
    padding: 30px 0;
  }
  .top-banner .todays-paper-banner .todays-paper .computer {
    width: 600px;
  }
  .top-banner .todays-paper-banner .paper {
    top: 45px;
    left: 95px;
    width: 410px;
  }
  .top-banner .todays-paper-banner .text-part {
    max-width: 450px;
  }
  .top-banner .todays-paper-banner .text-part h1 {
    font-size: 36px;
    line-height: 42px;
  }
}
@media screen and (max-width: 950px) and (min-width: 571px) {
  .top-banner .todays-paper-banner .todays-paper .computer {
    width: 500px;
  }
  .top-banner .todays-paper-banner .paper {
    top: 35px;
    left: 77px;
    width: 345px;
  }
  .top-banner .todays-paper-banner .text-part {
    padding-right: 10px;
    max-width: 350px;
  }
  .top-banner .todays-paper-banner .text-part h1 {
    font-size: 30px;
    line-height: 36px;
  }
}
@media screen and (max-width: 570px) {
  .top-banner {
    padding: 30px 0 0;
  }
  .top-banner .todays-paper-banner {
    flex-wrap: wrap;
    text-align: center;
  }
  .top-banner .todays-paper-banner .todays-paper .computer {
    width: 100%;
  }
  .top-banner .todays-paper-banner .todays-paper .computer img {
    content: url(../../../public/icons/mobile.png);
    width: 338px;
    margin: 0 auto;
  }
  .top-banner .todays-paper-banner .paper {
    top: 36px;
    left: 62px;
    width: 196px;
    --bs-aspect-ratio: calc(190 / 156 * 100%);
  }
  .top-banner .todays-paper-banner .paper img {
    content: url(https://epaper.thehindu.com/ccidist-ws/th/th_delhi/issues/28544/OPS/Public/GT5B02053.1%2BGT5B02053.1_thumbnail-284.jpg?rev=2023-03-14T01:01:35+05:30);
  }
  .top-banner .todays-paper-banner .text-part {
    padding-right: 0px;
    max-width: 100%;
    width: 100%;
    order: -1;
    margin-bottom: 20px;
    text-align: center;
  }
  .top-banner .todays-paper-banner .text-part h1 {
    font-size: 20px;
    line-height: 24px;
  }
  .top-banner .todays-paper-banner .text-part .basic-btn {
    margin: 0 auto;
  }
}
@media screen and (min-width: 310px) and (max-width: 340px) {
  .top-banner .todays-paper-banner .todays-paper .computer img {
    content: url(../../../public/icons/mobile-small.png);
    width: 300px;
  }
  .top-banner .todays-paper-banner .paper {
    top: 35px;
    left: 55px;
    width: 175px;
    --bs-aspect-ratio: calc(190 / 158 * 100%);
  }
}
@media screen and (min-width: 200px) and (max-width: 309px) {
  .top-banner .todays-paper-banner .todays-paper .computer img {
    content: url(../../../public/icons/mobile-small.png);
    width: 270px;
  }
  .top-banner .paper {
    top: 30px !important;
    left: 50px !important;
    width: 157px !important;
    --bs-aspect-ratio: calc(190 / 157 * 100%) !important;
  }
}
/* Todays Paper Banner starts **/
.section-title {
  font-family: "Playfair Display", serif;
  font-weight: bold;
  color: #000;
  font-size: 40px;
  text-align: center;
  margin: 0 auto 30px;
}

@media screen and (max-width: 570px) {
  .section-title {
    font-size: 22px;
    margin: 0 auto 20px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 571px) {
  .section-title {
    font-size: 32px;
    margin: 0 auto 25px;
  }
}
/* Subscribe Boxes starts */
.subscribe-boxes {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.subscribe-boxes .box {
  display: flex;
  margin: 10px 1% 15px;
  width: 24%;
  flex-wrap: wrap;
  padding: 15px 0 15px 15px;
  background: #f1f1f1;
  border-radius: 10px;
  color: #000000;
}
.subscribe-boxes .box:first-child {
  margin-left: 0;
}
.subscribe-boxes .box:last-child {
  margin-right: 0;
}
.subscribe-boxes .box p {
  width: calc(100% - 100px);
  padding-right: 10px;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 10px;
}
.subscribe-boxes .box img {
  width: 100px;
  height: 100px;
  margin-top: -10px;
}
.subscribe-boxes .box a {
  font-family: "Merriweather Sans", sans-serif;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
  text-decoration: none;
  width: max-content;
  position: relative;
  margin-bottom: 5px;
}
.subscribe-boxes .box a::after {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  content: "";
  height: 1px;
  background: #000000;
  transition: all 0.3s;
}
.subscribe-boxes .box a:hover::after {
  width: 0;
}

@media screen and (max-width: 570px) {
  .subscribe-boxes {
    flex-wrap: wrap;
  }
  .subscribe-boxes .box {
    margin: 20px 10px;
    padding: 10px 0 10px 10px;
    border-radius: 8px;
    width: 48%;
    margin: 6px 2%;
  }
  .subscribe-boxes .box:nth-child(even) {
    margin-right: 0;
  }
  .subscribe-boxes .box:nth-child(odd) {
    margin-left: 0;
  }
  .subscribe-boxes .box p {
    width: calc(100% - 75px);
    font-size: 14px;
    line-height: 20px;
  }
  .subscribe-boxes .box img {
    width: 75px;
    height: 75px;
    margin-top: -7px;
  }
  .subscribe-boxes .box a {
    font-size: 12px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 571px) {
  .subscribe-boxes .box {
    margin: 15px 8px;
  }
  .subscribe-boxes .box p {
    width: calc(100% - 75px);
    font-size: 14px;
    line-height: 20px;
  }
  .subscribe-boxes .box img {
    width: 75px;
    height: 75px;
    margin-top: -7px;
  }
  .subscribe-boxes .box a {
    font-size: 12px;
  }
}
/* Subscribe Boxes starts */
@media screen and (max-width: 570px) {
  .mobile-scroll-row {
    white-space: nowrap;
    overflow-x: auto;
    margin: 15px 0 0;
    display: block;
    padding-bottom: 20px;
  }
  .mobile-scroll-row .col-12 {
    display: inline-block !important;
    width: 80%;
    padding: 0;
    margin: 0 10px;
  }
  .mobile-scroll-row .col-12:first-child {
    margin-left: 0;
  }
  .mobile-scroll-row .col-12:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 1000px) and (min-width: 571px) {
  .tab-scroll-row {
    white-space: nowrap;
    overflow-x: auto;
    margin: 15px 0 0;
    display: block !important;
    padding-bottom: 20px;
  }
  .tab-scroll-row::-webkit-scrollbar {
    display: none;
  }
  .tab-scroll-row .col-12 {
    display: inline-block !important;
    width: 40%;
  }
}
.quote-list .quotes {
  position: relative;
}
.quote-list .quotes::after {
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  display: block;
  border-right: 1px solid rgba(0, 0, 0, 0.1450980392);
  top: 0;
  right: -11px;
}
.quote-list .owl-nav .owl-prev {
  position: absolute;
  top: calc(50% - 40px);
  left: -35px;
  opacity: 0.3;
}
/* .quote-list .owl-nav .owl-prev .prev-slide {
  background: url(../../public/icons/slider-rightarrow.svg) no-repeat scroll 0 0;
  transform: rotate(180deg);
  width: 24px;
  height: 36px;
  display: block;
} */
.quote-list .owl-nav .owl-prev:hover {
  text-decoration: none;
  opacity: 0.7;
}
.quote-list .owl-nav .owl-prev.disabled {
  opacity: 0.3;
}
.quote-list .owl-nav .owl-prev.disabled:hover {
  text-decoration: none;
  opacity: 0.3;
}
.quote-list .owl-nav .owl-next {
  position: absolute;
  top: calc(50% - 40px);
  right: -35px;
  opacity: 0.3;
}
/* .quote-list .owl-nav .owl-next .next-slide {
  background: url(../../../public/icons/slider-rightarrow.svg) no-repeat scroll 0 0;
  width: 24px;
  height: 36px;
} */
.quote-list .owl-nav .owl-next:hover {
  text-decoration: none;
  opacity: 0.5;
}
.quote-list .owl-nav .owl-next.disabled {
  opacity: 0.3;
}
.quote-list .owl-nav .owl-next.disabled:hover {
  text-decoration: none;
  opacity: 0.3;
}

.quotes {
  width: 100%;
}
.quotes .picture {
  width: 100%;
  position: relative;
  --bs-aspect-ratio: calc(9 / 16 * 100%);
  margin-bottom: 50px;
}
.quotes .picture::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.quotes .picture::after {
  content: "";
  width: 88px;
  height: 56px;
  background: url(../../../public/icons/article-quote-red.svg);
  background-size: 88px 56px;
  display: block;
  z-index: 2;
  position: absolute;
  bottom: -40px;
  left: 0;
}
.quotes .picture > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.quotes .picture img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
  min-height: unset;
}
.quotes p {
  font-family: "Merriweather", serif;
  color: #000000;
  font-size: 22px;
  line-height: 32px;
  font-style: italic;
  margin: 0;
  text-align: left;
  min-height: 260px;
  white-space: pre-wrap;
}
.quotes p.name {
  color: #B00020;
  font-style: normal;
  line-height: normal;
  margin-top: 20px;
  min-height: auto;
}
.quotes p.name span {
  display: block;
  font-family: "Merriweather Sans", sans-serif;
  color: #B00020;
  font-size: 16px;
  margin-top: 5px;
}

@media screen and (max-width: 570px) {
  .quotes .picture {
    margin-bottom: 40px;
  }
  .quotes .picture::after {
    width: 66px;
    height: 42px;
    background-size: 66px 42px;
    bottom: -30px;
  }
  .quotes p {
    font-size: 16px;
    line-height: 26px;
    min-height: 160px;
  }
  .quotes p.name {
    margin-top: 15px;
  }
  .quotes p.name span {
    font-size: 12px;
    margin-top: 3px;
  }

  .mobile-side-border.after-border-right {
    position: relative;
    height: max-content;
  }
  .mobile-side-border.after-border-right::after {
    display: block;
    content: "";
    height: 100%;
    width: 1px;
    border-right: 1px solid rgba(0, 0, 0, 0.1450980392);
    position: absolute;
    top: 0;
    right: -12px;
  }
  .mobile-side-border.after-border-left {
    position: relative;
    height: max-content;
  }
  .mobile-side-border.after-border-left::before {
    content: "";
    height: 100%;
    width: 1px;
    border-left: 1px solid rgba(0, 0, 0, 0.1450980392);
    position: absolute;
    top: 0;
    left: -12px;
  }
}
@media screen and (max-width: 1080px) {
  .quote-list.owl-carousel {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    padding-bottom: 15px;
  }
  .quote-list.owl-carousel::-webkit-scrollbar {
    height: 2px;
    transition: width 0.3s;
    cursor: pointer;
    display: block;
  }
  .quote-list.owl-carousel::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3137254902);
    border-radius: 50px;
  }
  .quote-list.owl-carousel .item {
    display: inline-block;
    vertical-align: top;
    margin: 0 7px;
    width: 350px;
    max-width: 45%;
  }
  .quote-list.owl-carousel .item:first-child {
    margin-left: 0;
  }
  .quote-list.owl-carousel .item:last-child {
    margin-right: 0;
    border-right: none;
  }
  .quote-list.owl-carousel .item:last-child::after {
    border-right: none;
    top: 0;
    right: 0px;
  }
  .quote-list.owl-carousel .item::after {
    right: -10px;
  }
  .quote-list.owl-carousel .item .picture {
    width: 100%;
  }
}
@media screen and (max-width: 570px) {
  .quote-list.owl-carousel {
    padding-bottom: 10px;
  }
  .quote-list.owl-carousel .item {
    width: 80%;
    max-width: 80%;
  }
}
/* Accordion starts*/
.accordion .accordion-item .accordion-header .accordion-button {
  padding-left: 0;
  font-family: "Merriweather", serif;
  font-size: 22px;
  transition: all 0.3s linear;
}
.accordion .accordion-item .accordion-header .accordion-button:hover, .accordion .accordion-item .accordion-header .accordion-button:focus, .accordion .accordion-item .accordion-header .accordion-button:active {
  text-decoration: none;
  box-shadow: none;
}
.accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background: none;
  box-shadow: none;
  font-weight: bold;
  padding-bottom: 0;
  color: #000000;
  transition: all 0.3s linear;
}
.accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion .accordion-item .accordion-body {
  padding-bottom: 5px;
  padding-left: 0;
}
.accordion .accordion-item .accordion-body p {
  font-size: 18px;
}

.contact-line {
  font-family: "Merriweather", serif;
  font-size: 22px;
  font-weight: normal;
}
.contact-line span {
  margin-right: 15px;
}
.contact-line span a {
  text-decoration: underline;
  transition: all 0.3s;
}
.contact-line span a:hover {
  text-decoration: none;
}

@media screen and (max-width: 570px) {
  .accordion .accordion-item .accordion-header .accordion-button {
    font-size: 16px;
    padding-right: 0;
    padding-left: 3px;
  }
  .accordion .accordion-item .accordion-body {
    padding: 10px 5px 0;
  }
  .accordion .accordion-item .accordion-body p {
    font-size: 14px;
  }

  .contact-line {
    font-size: 16px;
  }
  .contact-line span {
    margin-right: 0;
    display: block;
  }
}
@media screen and (max-width: 1000px) and (min-width: 571px) {
  .accordion .accordion-item .accordion-header .accordion-button {
    font-size: 18px;
    padding-right: 0;
    padding-left: 3px;
  }
  .accordion .accordion-item .accordion-body {
    padding: 10px 5px 0;
  }
  .accordion .accordion-item .accordion-body p {
    font-size: 16px;
  }

  .contact-line {
    font-size: 18px;
  }
  .contact-line span {
    margin-right: 10px;
  }
}
/* Accordion ends*/
/* Sliding Plans starts */
.sliding-tab .sliding-btns {
  width: max-content;
  margin: 0 auto;
  display: flex;
  background: #E9E9E9;
  border: 3px solid #E9E9E9;
  border-radius: 5px;
  position: relative;
}
.sliding-tab .sliding-btns .slider {
  background: #ffffff;
  position: absolute;
  height: 100%;
  width: 150px;
  z-index: 1;
  border-radius: 4px;
  left: 0;
  top: 0;
  transition: all 0.3s;
}
.sliding-tab .sliding-btns .slide-btn {
  position: relative;
  z-index: 2;
  width: 150px;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  opacity: 0.7;
}
.sliding-tab .sliding-btns .slide-btn.active, .sliding-tab .sliding-btns .slide-btn:hover, .sliding-tab .sliding-btns .slide-btn:focus {
  opacity: 1;
  outline: none;
  box-shadow: none;
  text-decoration: none;
}
.sliding-tab .sliding-content {
  margin-top: 30px;
}
.sliding-tab .sliding-content .slide {
  width: 100%;
  display: none;
}

.plan-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.plan-boxes .box {
  display: block;
  width: 28%;
  margin: 0 1.5%;
  border: 1px solid;
  border-top: 7px solid;
  border-color: #8B8B8B;
  border-radius: 4px;
  padding: 20px 30px;
  text-align: center;
}
.plan-boxes .box:first-child {
  margin-left: 0;
}
.plan-boxes .box:last-child {
  margin-right: 0;
}
.plan-boxes .box.black-box {
  border-color: #000000;
}
.plan-boxes .box.red-box {
  border-color: #B00020;
}
.plan-boxes .box.flat-color {
  background: #999999;
  border-color: #333333;
  color: #ffffff;
}
.plan-boxes .box.flat-color .offer-list li::before {
  color: #ffffff;
}
.plan-boxes .box.flat-color.black-box {
  background: #333333;
  border-color: #000000;
  color: #ffffff;
}
.plan-boxes .box.flat-color.red-box {
  background: #B00020;
  border-color: #640000;
  color: #ffffff;
}
.plan-boxes .box.flat-color .basic-btn {
  background: #ffffff;
  color: #000000;
  border-color: #ffffff;
}
.plan-boxes .box .offer-name {
  margin-top: 10px;
  font-family: "Merriweather Sans", sans-serif;
  font-size: 16px;
}
.plan-boxes .box .offer-price {
  font-family: "Merriweather", serif;
  font-size: 28px;
  margin: 5px auto 10px;
  font-weight: bold;
}
.plan-boxes .box .offer-price span {
  font-size: 16px;
}
.plan-boxes .box .offer-list {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  min-height: 160px;
}
.plan-boxes .box .offer-list li {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1450980392);
  font-family: "Merriweather Sans", sans-serif;
  font-size: 14px;
  padding: 13px 0;
}
.plan-boxes .box .offer-list li:last-child {
  border-bottom: none;
}
.plan-boxes .box .offer-list li::before {
  content: "✓";
  margin-right: 6px;
  color: #B00020;
}
.plan-boxes .box .basic-btn {
  width: 80%;
  margin: 0 auto;
  height: 44px;
}
.plan-boxes .box .basic-btn::after {
  top: -2px;
}

@media screen and (max-width: 570px) {
  .sliding-tab .sliding-btns {
    border: 2px solid #E9E9E9;
    border-radius: 3px;
  }
  .sliding-tab .sliding-btns .slider {
    width: 120px;
    border-radius: 3px;
  }
  .sliding-tab .sliding-btns .slide-btn {
    width: 120px;
    font-size: 14px;
  }
  .sliding-tab .sliding-content {
    margin-top: 20px;
  }

  .plan-boxes .box {
    width: 100%;
    margin: 10px 0;
    padding: 20px 20px;
  }
  .plan-boxes .box:first-child {
    margin-top: 0;
  }
  .plan-boxes .box:last-child {
    margin-bottom: 0;
  }
  .plan-boxes .box .offer-name {
    font-size: 14px;
  }
  .plan-boxes .box .offer-price {
    font-size: 24px;
  }
  .plan-boxes .box .offer-price span {
    font-size: 13px;
  }
  .plan-boxes .box .offer-list {
    min-height: auto;
    margin-bottom: 20px;
  }
  .plan-boxes .box .offer-list li {
    font-size: 14px;
    padding: 10px 0;
  }
  .plan-boxes .box .basic-btn {
    width: 80%;
    margin: 0 auto;
  }
  .plan-boxes .box .basic-btn::after {
    top: -2px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 571px) {
  .sliding-tab .sliding-btns {
    border: 2px solid #E9E9E9;
    border-radius: 3px;
  }
  .sliding-tab .sliding-btns .slider {
    width: 135px;
    border-radius: 3px;
  }
  .sliding-tab .sliding-btns .slide-btn {
    width: 135px;
    font-size: 16px;
  }
  .sliding-tab .sliding-content {
    margin-top: 20px;
  }

  .plan-boxes .box {
    padding: 20px 20px;
    margin: 10px 1%;
  }
  .plan-boxes .box .offer-name {
    font-size: 16px;
    margin-top: 5px;
  }
  .plan-boxes .box .offer-price {
    font-size: 26px;
  }
  .plan-boxes .box .offer-price span {
    font-size: 16px;
  }
  .plan-boxes .box .offer-list {
    min-height: 140px;
  }
  .plan-boxes .box .offer-list li {
    font-size: 14px;
    padding: 10px 0;
  }
  .plan-boxes .box .basic-btn {
    width: 80%;
    margin: 0 auto;
  }
  .plan-boxes .box .basic-btn::after {
    top: -2px;
  }
}
/* Sliding Plans ends */
</style>