<template>
  <router-view v-slot="{ Component }">
    <keep-alive exclude="SearchPage,MyAccountPage">
      <component :is="Component" />
    </keep-alive>
  </router-view>
  <google-sign-in></google-sign-in>
</template>

<script>
import pianoInit from '@/utils/piano'
import GoogleSignIn from "@/components/ReaderPage/GoogleSignIn.vue";

export default {
  name: "App",
  components: {
    GoogleSignIn,
  },
  methods: {
    subscribedUsersAction(days) { 
      console.log("subscribed user")
      this.$logger.debug("piano init... subscribe users action...");
      this.$logger.debug('Before action: ' + this.$store.state.auth.userType)
      this.$store.state.auth.userType = "SUBCRIBE"
      if(typeof(days) == 'number'){
        if (window.$("#datepicker")){
          window.$("#datepicker").datepicker("option", "minDate", -days);
          this.$logger.debug("After action: " + this.$store.state.auth.userType)
          }
          else{
              console.log("Datepicker not found")
          }
        } 
      else{
        if (window.$("#datepicker"))
        window.$("#datepicker").datepicker("option", "minDate", -180);
      this.$logger.debug("After action: " + this.$store.state.auth.userType)
      }
    },
    notSubscribedUsersAction() { },
    fireTrialUserEventAtFirst() {
      const e = {
        detail: {
          type: "user_type",
          user_type: "TRIAL",
        },
      };

      const ev = new CustomEvent(e.detail.type, e);
      const iframe = window.document.getElementsByTagName('iframe')[0] ?? null;

      if (iframe !== null) {
        iframe.contentWindow.dispatchEvent(ev);
      } else {
        this.$logger.debug(`emitting ${e.detail.type} failed, iframe not mounted in DOM...`);
      }
    },
    trialUsersAction() {
      this.$logger.debug("piano init... Trial users action...");
      this.$logger.debug('Before action: ' + this.$store.state.auth.userType)
      this.$store.state.auth.userType = "TRIAL";
      this.fireTrialUserEventAtFirst();
      if (window.$("#datepicker"))
        window.$("#datepicker").datepicker("option", "minDate", 0);
      this.$logger.debug("After action: " + this.$store.state.auth.userType)
    },
  },
  created() {
    pianoInit(
      {
        subscribedUsersAction: this.subscribedUsersAction,
        notSubscribedUsersAction: this.notSubscribedUsersAction,
        trialUsersAction: this.trialUsersAction
      }
    );
  }
};

</script>

<style>
body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: "Fira Sans" !important;
}

.ui-datepicker-trigger {
  border: none !important;
  background: none !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 5px 12px;
  margin: 1px;
  border: 1px solid lightgrey;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #337ab7 !important;
  border: 1px solid #337ab7;
  color: white !important;
}

.dataTables_paginate {
  margin-top: 10px;
  float: left !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.next {
  display: none;
}

/* ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  z-index: 9999;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
} */
</style>
