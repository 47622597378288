class Article {
    constructor(id, headline, content, date, edition, articleURL, imageURL = null, pageno) {
        this.id = id
        this.headline = headline
        this.content = content
        this.imageURL = imageURL
        this.articleURL = articleURL
        this.date = date
        this.edition = edition
        this.pageno = pageno
    }

    static readerURL(ed, iid, pg, filename) {
        return `${process.env.VUE_APP_WEB_READER_URL}?epub=https:/ccidist-ws/th/${ed}/issues/${iid}&page=${pg}&article=${filename}`
    }

    static htmlURL(ed, iid, path) {
        const assetURLEncoded = encodeURIComponent(`${process.env.VUE_APP_WS_API_URL}${ed}/issues/${iid}/OPS/${path}`)
        return `/articleshare?articleurl=${assetURLEncoded}`
    }

    static imageURL(ed, iid, path) {
        return `${process.env.VUE_APP_WS_API_URL}${ed}/issues/${iid}/OPS/${path}`
    }

    static trimContent(content, contentFor = 'body') {
        if (contentFor === 'headline') return content.replace(/^(.{120}[^\s]*).*/, "$1...")
        return content.replace(/^(.{180}[^\s]*).*/, "$1...")
    }

    static fromJSON = (obj) => {
        return new Article(
            obj.article_id,
            obj.articleheadline ?
                obj.articleheadline[0] :
                this.trimContent(obj.CONTENT[0], 'headline'),
            this.trimContent(obj.CONTENT[0]),
            obj.issue_name,
            obj.publication_name,
            this.htmlURL(
                obj.publication_id,
                obj.issue_id,
                obj.articlefilename
            ),
            obj.articlephoto ? this.imageURL(
                obj.publication_id,
                obj.issue_id,
                obj.articlephoto
            ) : null,
            obj.pageno
        )
    }
}

export default Article