<template>
    <div class="accordion accordion-flush" :id="id">
        <slot></slot>
    </div>
</template>

<script>

export default {
    props: {
        id: {
            type: String,
            required: true
        }
    }
}

</script>