import Logger from "@/utils/logToConsole"

const baseUrl = process.env.VUE_APP_WS_API_URL

const getBaseUrl = (city, date) => `${baseUrl}${city}/issues/?epubName=${city}_web&limit=1&skipSections=true&fromDate=${date}&toDate=${date}`

export default (city, date, options={}) => {
    const url = getBaseUrl(city, date)
    Logger.debug(`getReaderUrl: ${url}`)
    return fetch(url, options)
}