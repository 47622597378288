export default {
    getLoadingState: (state) => state.loading,
    getIframeUrl: (state) => state.iframeUrl,
    getDate: (state) => state.date,
    getRegion: (state) => state.region,
    getEdition: (state) => state.edition,
    getRegionsList: (state) => state.regionsList,
    getEditionsList: (state) => state.editionsList,
    getSessionLimitExceeded: (state) => state.session_limit_exceeded,
    getSessionDevicesInfo: (state) => state.session_devices_info,
}