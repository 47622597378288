<template>
    <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="rightSideDrawer"
        aria-labelledby="offcanvasNavbarLabel"
    >
        <div class="offcanvas-body">
            <div class="myaccount-top">
                <div class="avatar">{{ singleLetter }}</div>
                <div class="user-info">
                    <div class="user-name">{{ userName }}</div>
                    <div class="user-email">{{ emailId }}</div>
                </div>
                <a class="btn btn-edit-profile" @click="routeTo('myaccount')">Change Password</a>
            </div>
            <div class="myaccount-menus">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <div class="d-flex p-1 border-bottom">
                            <div class="p-2 flex-grow-1" @click="routeTo('myaccount')">MY PROFILE</div>
                            <div class="p-2">
                                <i class="fa fa-chevron-right fa-fw" aria-hidden="true"></i>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div class="d-flex p-1 border-bottom">
                            <div class="p-2 flex-grow-1" @click="routeTo('favourites')">MY FAVOURITE ARTICLES</div>
                            <div class="p-2">
                                <i class="fa fa-chevron-right fa-fw" aria-hidden="true"></i>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item">
                        <div
                            class="d-flex p-1 border-bottom"
                            @click="this.$store.dispatch('auth/logout')"
                        >
                            <div class="p-2 flex-grow-1">LOGOUT</div>
                            <div class="p-2">
                                <i class="fa fa-chevron-right fa-fw" aria-hidden="true"></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // data() {
    //     return {
    //         userDetails: window.tp.pianoId.getUser()
    //     }
    // },
    computed: {
        userDetails: () => window.tp.pianoId.getUser(),
        userName() {
            if (this.userDetails !== null && this.userDetails.firstName) {
                return this.userDetails.firstName.trim()
            } else {
                return '';
            }
        },
        emailId() {
            if (this.userDetails !== null && this.userDetails.email) {
                return this.userDetails.email
            } else {
                return '';
            }
        },
        singleLetter() {
            if (this.userDetails !== null)
                if (this.userDetails.firstName) {
                    return this.userDetails.firstName[0].toUpperCase();
                } else if (this.userDetails.email) {
                    return this.userDetails.email[0].toUpperCase();
                }
            return "N";
        }
    },
    methods: {
        routeTo(val) {
            this.$emit('routeEvent', val)
        }
    },
    created() {
        this.$logger.debug("Piano ID user details:")
        this.$logger.debug(window.tp.pianoId.getUser())
    }
}
</script>

<style scoped>
.offcanvas {
    width: 275px;
    max-width: 300px;
}
.offcanvas-body {
    padding: 0;
    margin: 0;
}
.myaccount-top {
    padding: 25px 0;
    background-color: #333;
    height: max-content;
    font-family: "Fira Sans";
}
.myaccount-menus {
    font-family: "Fira Sans";
    font-size: 14px;
    color: #707070;
}
.avatar {
    width: 60px;
    height: 60px;
    font-size: 36px;
    background-color: #fff;
    border-radius: 50%;
    padding: 8px;
    line-height: 1.15em;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.user-info {
    text-align: center;
    display: block;
    margin: 10px auto;
    color: #fff;
}
.user-name {
    font-size: 18px;
}
.user-email {
    font-size: 14px;
}
.btn-edit-profile {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
    border-radius: 15px;
    width: 165px;
    margin: 0 auto;
    display: block;
}
.nav-item {
    cursor: pointer;
}
@media only screen and (max-width: 576px) {
    .offcanvas {
        width: 260px;
    }
}
</style>