<template>
    <the-header></the-header>
    <page-info-nav heading="Search" icon="fa fa-chevron-left"></page-info-nav>
    <div class="container bg-white mt-5 py-3">
        <div class="row align-items-end justify-content-evenly search-box mx-3">
            <div class="col-10 pt-3">
                <div class="mb-3">
                    <label class="form-label">Search Text</label>
                    <input v-model="keywords" type="text" class="form-control" />
                </div>
                <div class="mb-3 row" v-if="adv_search">
                    <div class="col-md-4 col-12">
                        <label class="form-label">From Date</label>
                        <input v-model="fromDate" type="date" class="form-control" />
                    </div>
                    <div class="col-md-4 col-12">
                        <label class="form-label">To Date</label>
                        <input v-model="toDate" type="date" class="form-control" />
                    </div>
                    <div class="col-md-4 col-12">
                        <label class="form-label">Edition</label>
                        <select v-model="region" class="form-control">
                            <option
                                :value="val.id"
                                v-for="val in regionsList"
                                :key="val"
                            >{{ val.title }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-auto mb-3">
                <button type="submit" @click="getSearchResults" class="btn btn-primary">Submit</button>
            </div>
            <a class="nav-link adv-search" @click="toggle_adv_search">Advanced Search</a>
        </div>
        <div class="mx-3">
            <div v-show="isloading">Loading</div>
            <table class="table" id="pagination">
                <thead>
                    <tr>
                        <th scope="col" class="col-6">Title</th>
                        <th scope="col">Page No</th>
                        <th scope="col">Edition</th>
                        <th scope="col">Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(result, index) in results" :key="index">
                        <td class="result-title">
                            <a :href="result.articleURL">{{ result.headline }}</a>
                        </td>
                        <td>{{ result.pageno }}</td>
                        <td>{{ result.edition }}</td>
                        <td>{{ result.date }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import TheHeader from '@/components/commonHeader.vue'
import PageInfoNav from "@/components/PageInfoNav"
import SearchRequestHandler from "@/request-handlers/SearchRequestHandler"
import getTodaysDate from "@/utils/getTodaysDate"
import getAllRegionsList from "@/request-handlers/getAllRegionsList"
import FormatEditionTitle from "@/utils/formatEditionTitle"

export default {
    name: 'SearchPage',
    props: {
        query: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            adv_search: false,
            region: process.env.VUE_APP_PUB + "_" + "chennai",
            keywords: "",
            fromDate: getTodaysDate(),
            toDate: getTodaysDate(),
            regionsList: [],
            results: []
        }
    },
    components: {
        TheHeader,
        PageInfoNav
    },
    computed: {
        isloading() {
            return this.$store.getters['reader/getLoadingState']
        }
    },
    watch: {
        region() {
            this.$logger.debug(this.region);
        }
    },
    methods: {
        async getRegionList() {
            const res = await getAllRegionsList().then(res => res.json()).catch(() => [])
            if (!res.publications) this.regionsList = []
            this.regionsList = res.publications.map(val => {
                return {
                    id: val.id,
                    title: FormatEditionTitle(val.title)
                }
            })
        },
        async getSearchResults() {
            this.$store.commit('reader/changeLoadingState', true)
            this.$logger.debug(`keywords: ${this.keywords}`);
            const result = await SearchRequestHandler(this.keywords, this.fromDate, this.toDate, null, this.region)
            this.results = result
            this.recreateDataTable()
            this.$store.commit('reader/changeLoadingState', false)
        },
        toggle_adv_search() {
            this.adv_search = !this.adv_search
        },
        recreateDataTable() {
            if (this.dt) this.dt.destroy()
            this.$nextTick(() => {
                this.dt = window.$('#pagination').DataTable({
                    "searching": false,
                    "lengthChange": false,
                    "bInfo": false
                })
            });
        }
    },
    beforeMount() {
        this.getRegionList()
        this.$logger.debug(`query param recieved: ${this.query}`);
        if (this.query) {
            this.keywords = this.query
            this.fromDate = "2022-03-01"
            this.getSearchResults()
        }
    }
}

</script>

<style scoped>
.search-box {
  background-color: #f0f0f0;
}
#result {
  position: relative;
}
table th {
  font-weight: 500;
}
table td {
  font-size: 14px;
}
table td a {
  text-decoration: none;
}
.search-box {
  background-color: #f0f0f0;
}
table.dataTable thead th {
  border-bottom: 0.1em solid #ddd;
}
table.dataTable.no-footer,
.table > :not(:last-child) > :last-child > * {
  border-bottom: 0.1em solid #ddd;
}
.result-title a,
.adv-search {
  cursor: pointer;
  color: #337ab7;
  font-weight: 500;
}
.btn-primary {
  background-color: #337ab7;
  border-color: #337ab7;
}
</style>