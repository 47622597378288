<template>
  <the-modal modal-id="datepickermodal">
    <div class="modal-content" id="datepicker"></div>
  </the-modal>

  <nav class="navbar navbar-light bg-white m-0">
    <div class="container-fluid">
      <a class="navbar-brand">
        <img class="logo" :src="logoPath" alt="" />
      </a>
      <div class="d-flex menu-items">
        <div
          class="px-2 desktop-nav"
          data-bs-toggle="modal"
          data-bs-target="#datepickermodal"
          id="hideModal"
        >
          <span class="prefix-icon"
            ><img src="../../public/icons/calendar.svg" alt=""
          /></span>
          <span class="menu-text">{{ date }}</span>
        </div>
      </div>
    </div>
  </nav>

  <div class="p-5">
    <div class="p-3" v-if="editions.length <= 0">
      No issues available for this date: {{ date }}
    </div>
    <div class="p-3" v-if="isLoading">Loading...</div>
    <div class="container bg-white" v-else>
      <div class="row">
        <div
          class="col-12 px-5"
          v-for="(edition, index) in editions"
          :key="index"
        >
          <h3 class="text-center pt-3">{{ edition.title }}</h3>
          <table class="table">
            <thead>
              <tr>
                <th>Supplements</th>
                <th class="text-center">Pages</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(supplement, index1) in edition.supplements"
                :key="index1"
              >
                <td class="col-8">{{ supplement.title }}</td>
                <td class="text-center">{{ supplement.pages }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getFormattedDate from "@/utils/getFormattedDate";
import TheModal from "@/components/ReaderPage/TheModal.vue";
import getRegionList from "@/request-handlers/getRegionList";
import getEditionsList from "@/request-handlers/getEditionsList";
import FormatEditionTitle from "@/utils/formatEditionTitle";

export default {
  components: {
    TheModal,
  },
  data() {
    return {
      date: getFormattedDate(new Date()),
      editions: [],
      isLoading: false,
      logoPath: process.env.BASE_URL + process.env.VUE_APP_LOGO_FILE
    };
  },
  computed: {
    formattedDate() {
      return this.date.trim() !== "" ? getFormattedDate(this.date) : "";
    },
  },
  methods: {
    async getData(date) {
      this.isLoading = true
      const result = await getRegionList(date, {
        credentials: process.env.VUE_APP_INCLUDE_CREDENTIALS,
      })
        .then((res) => res.json())
        .catch(() => []);

      if (result.publications && result.publications.length > 0) {
        this.editions = result.publications
          .filter((el) => el.issues.web && el.issues.web.length > 0)
          .map((el) => {
            return {
              id: el.id,
              title: FormatEditionTitle(el.title),
              pages: el.issues.web[0].pageCount,
            };
          });

        this.editions.forEach(async (edition) => {
          const result = await getEditionsList(edition.id, date, {
            credentials: process.env.VUE_APP_INCLUDE_CREDENTIALS,
          })
            .then((res) => res.json())
            .catch(() => []);
          // Change the state.editionsList
          if (result.publications && result.publications.length > 0) {
            edition.supplements = result.publications
              .filter((el) => el.issues.web && el.issues.web.length > 0)
              .map((el) => {
                return {
                  id: el.id,
                  title: FormatEditionTitle(el.title),
                  pages: el.issues.web[0].pageCount,
                };
              });
          }
        });
      }

      this.isLoading = false

      this.$logger.debug(this.editions);
    },
  },
  mounted() {
    window.$("#datepicker").datepicker({
      defaultDate: new Date(),
      changeMonth: true,
      changeYear: true,
      dateFormat: "yy-mm-dd",
      maxDate: 0,
      onSelect: (d) => {
        // Trigger date change event
        this.date = getFormattedDate(d);
        this.getData(d);
        // Close modal
        window.$("#hideModal").click();
      },
    });
  },
};
</script>

<style scoped>
.reader {
  width: 100%;
  height: 100vh;
  background-color: lightgray;
  position: relative;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.lds-ring {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.logo {
  width: 80%;
}

.prefix-icon,
.suffix-icon {
  margin-right: 5px;
}

.prefix-icon img {
  width: 20px;
}

.suffix-icon img {
  width: 9px;
}

.menu-text {
  margin-right: 5px;
  font-size: 15px;
  font-weight: normal;
  color: black;
}

.nav-link {
  color: black;
}

.menu-items > div {
  cursor: pointer;
  text-align: center;
}

.menu-items > div::after {
  content: "";
  display: inline-block;
  margin-bottom: -0.5em;
  height: 1.5em;
  width: 1px;
  background-color: lightgrey;
  margin-left: 0.5em;
}

.menu-items > div:first-child::after,
.menu-items > div:last-child::after {
  content: "";
  background-color: transparent;
}

.dropdown-toggle::after {
  content: "";
  display: inline-block;
  margin: 0;
  transform: scale(0.7);
  vertical-align: middle;
  border: 0;
}
.region {
  text-align: left;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #000;
  cursor: pointer;
}

.region-active {
  color: green;
}

.region-active > .cover-img img {
  border: 1px solid green;
}

@media only screen and (max-width: 576px) {
  .desktop-nav {
    display: none;
  }
}

.cover-img {
  max-width: 200px;
  border: 1px solid #eee;
}

.cover-img img {
  width: 100%;
  max-height: 259.84px;
}

.edition-name {
  margin-top: 10px;
  font-size: 14px;
}

@media only screen and (max-width: 900px) {
  .desktop-nav {
    display: none;
  }
  .navbar-brand {
    width: 50%;
  }
  .logo {
    width: 100%;
  }
}
</style>
