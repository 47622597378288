import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import { getTodaysDateWithOffset } from '@/utils/getIndianTimezoneOffset'
import { getItem } from "@/utils/setCookie"

const readerModule = {
    namespaced: true,
    state: () => ({
        loading: false,
        iframeUrl: "",
        date: getItem('selecteddate') == null ? getTodaysDateWithOffset() : getItem('selecteddate'),
        region: getItem('selectedregion') == null ? '' : JSON.parse(getItem('selectedregion')),
        edition: getItem('selectededition') == null ? '' : JSON.parse(getItem('selectededition')),
        regionsList: [],
        editionsList: [],
        currentArticeId: '',
        favouriteArticles: null,
        isArticleViewUserEventFired: false,
        session_limit_exceeded: false,
        session_devices_info: []
    }),
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default readerModule