<template>
    <page-info-nav heading="Contact Us"></page-info-nav>
    <div class="container bg-white mt-5">
        <div class="row py-4 px-2">
            <div class="col-sm-12">
                <p class="m-0">
                    For e-Paper subscription related errors/queries & e-Paper web site features, please email <a href="mailto:epapercare@thehindu.co.in">epapercare@thehindu.co.in</a>. <strong>Toll free: 1800-102-1878</strong>
                    <br />
                    <br />
                    If you'd like to discuss a point with the Readers’ Editor (our newspaper's internal ombudsman), you can write to :<br />
                    The Readers’ Editor, 859 & 860, Anna Salai, Chennai 600002, India<br />
                    or fax the Readers’ Editor at (+91) (44) 28415325
                </p>
            </div>
        </div>
    </div>
</template>

<script>

import PageInfoNav from '@/components/PageInfoNav';

export default {
    components: {
        PageInfoNav
    }
}

</script>

<style scoped>
.col-sm-12 p {
    font-size: 14px;
}
.col-sm-12 a {
    text-decoration: none;
    color: #337AB7;
}
.col-sm-12 a:hover {
    text-decoration: underline;
    color: #2b71ad;
}
</style>