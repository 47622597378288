class Logger {
    static l_l = process.env.VUE_APP_LOGGING_LEVEL

    static error(message) {
        typeof message === "string" ? console.log('%c' + message, 'color: white; font-size: 10px; background: #FF7276; border-radius: 10px;padding: 8px 15px;'): console.log(message)
    }

    static debug(message) {
        if (this.l_l === "debug")
            typeof message === "string" ? console.log('%c' + message, 'color: black; font-size: 10px; background: #ffcf00; border-radius: 10px;padding: 8px 15px;') : console.log(message);
    }

    static info(message) {
        console.log('%c' + message, 'color: white; font-size: 10px; background: #43cf82; border-radius: 10px;padding: 8px 15px;');
    }
}

export default Logger