<template>
<!-- <div class="col-xl-4 col-md-4 col-sm-12 col-xs-12 col-12 mobile-footer-pad" ref="trending" v-html="footerHtml"> -->
  <div class="col-xl-4 col-md-4 col-sm-12 col-xs-12 col-12 mobile-footer-pad">
    <h5 class="footer-top-border">Trending on thehindu.com</h5>
    <div id="trending-on-site"></div>
    <h5 class="no-border mt-2">Trending on our Group sites</h5>
    <div id="trending-on-group-site-one"></div>   
    <div id="trending-on-group-site-two"></div> 
    <div id="trending-on-group-site-three"></div>  
  </div>
</template>

<!-- <script>
export default {
  data(){
    return {
      footerHtml:""
    }
  },
  mounted() {
    this.footertrending();
  },
  methods: {
    footertrending(){
      fetch('https://www.thehindubusinessline.com/.esi/footertrending-rx')
      .then(res => {
        return res.text()
      })
      .then(data => {
        this.footerHtml = data
      }).then(() => {
        console.log("trending");
        //data-src-template
        console.log(this.$refs.trending.querySelectorAll("img"))
        this.$refs.trending.querySelectorAll("img").forEach(element => {
          element.setAttribute("src", element.getAttribute("data-src-template"))
        })
      })
    }
  }
}
</script> -->

<style>
footer .footer-top-border {
  border-top: 5px solid #000;
  padding-top: 7px;
}
footer .footer-menu h5 {
  font-size: 14px;
  line-height: normal;
  font-family: "Merriweather Sans", sans-serif;
  color: #000000;
  font-weight: 800;
}
footer .footer-menu .footer-menu-list {
  list-style: none;
  display: inline-block;
  padding: 0;
  width: 100%;
}
footer .footer-menu .footer-menu-list li {
  font-size: 14px;
  line-height: normal;
  font-family: "Merriweather Sans", sans-serif;
  color: #000000;
  margin: 2px 0;
  position: relative;
  border-bottom: 1px solid #999999;
  padding: 10px 0;
}
/* footer css */
footer .footer-menu .footer-menu-list li a {
  font-family: "Merriweather Sans", sans-serif;
  text-decoration: none;
  color: inherit;
}
footer .footer-menu .footer-menu-list li:last-child {
  border-bottom: none;
}
@media screen and (max-width: 570px) {
  footer .footer-menu .footer-top-border {
    margin-top: 15px;
    border-top: 5px solid #000;
    padding-top: 15px;
  }
}
</style>