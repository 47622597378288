import Logger from "@/utils/logToConsole"

const baseUrl = process.env.VUE_APP_WS_API_URL

const getEditionsListUrl = (city, date) => `${baseUrl}/?json=true&fromDate=${date}&toDate=${date}&skipSections=true&os=web&includePublications=${city}*`

export default (city, date, options={}) => {
    let url = getEditionsListUrl(city, date)
    Logger.debug(`getEditionsListUrl: ${url}`)
    return fetch(url, options)
}