<template>

  <nav id="common-header" class="navbar navbar-light bg-white m-0">
    <div class="container-fluid justify-content-center">
      <a :href="iconNavDisable === true ? '#' : '/reader'">
        <div class="row py-2">
          <div class="col-12">
            <img class="logo" :src="logoPath" alt="" />
          </div>
        </div>
      </a>
    </div>
  </nav>
</template>

<script>

export default {
  props: {
    iconNavDisable: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      logoPath: process.env.BASE_URL + process.env.VUE_APP_LOGO_FILE
    }
  }
}
</script>

<style>
.logo {
  max-width: 250px;
}
</style>