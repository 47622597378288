<template>
    <div
        class="offcanvas offcanvas-end"
        id="leftSideDrawer"
        aria-labelledby="offcanvasNavbarLabel"
    >
        <div class="offcanvas-body">
            <div class="myaccount-menus">
                <ul class="nav flex-column">
                    <a href="/reader">
                        <li class="nav-item">
                            <div class="p-3 flex-grow-1 border-bottom">
                                <img
                                    style="max-width: 100%;"
                                    :src="logoPath"
                                />
                            </div>
                        </li>
                    </a>
                    <a href="/reader" class="text-decoration-none">
                        <li class="nav-item">
                            <div class="p-3 flex-grow-1 border-bottom">HOME</div>
                        </li>
                    </a>
                    <span class="mobile-menus">
                        <li class="nav-item" @click="hideModal">
                            <div
                                class="p-3 flex-grow-1 border-bottom"
                                id="mobileDate"
                                data-bs-toggle="modal"
                                data-bs-target="#datepickermodal"
                            >CHANGE DATE</div>
                        </li>
                        <li class="nav-item" @click="hideModal">
                            <div
                                class="p-3 flex-grow-1 border-bottom"
                                data-bs-toggle="modal"
                                data-bs-target="#regionlistmodal"
                                id="hideRegionModal"
                            >CHANGE EDITION</div>
                        </li>
                        <li class="nav-item" @click="hideModal">
                            <div
                                class="p-3 flex-grow-1 border-bottom"
                                data-bs-toggle="modal"
                                data-bs-target="#editionlistmodal"
                            >CHANGE SUPPLEMENT</div>
                        </li>
                    </span>
                    <li class="nav-item" v-for="(menu, index) in menuItems" :key="index">
                        <div class="p-3 flex-grow-1 border-bottom" @click="routeTo(menu)">{{ menu.title }}</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        menuItems: {
            type: Array,
            required: true
        }
    },
    methods: {
        hideModal() {
            window.$('#leftDrawer').click()
        },
        routeTo(detail) {
            window.$('#leftDrawer').click()
            if (this.userStatus === false && this.restrictedMenuHrefs.includes(detail.href)) {
                this.$store.dispatch('auth/login');
                return;
            }

            setTimeout(() => {
                if (detail.routeEvent && !detail.newTab) {
                    this.$router.push(`/${detail.href}`)
                } else {
                    window.open(detail.href, '_blank').focus();
                }
            }, 90);
        }
    },
    data() {
        return {
            logoPath: process.env.BASE_URL + process.env.VUE_APP_LOGO_FILE,
            restrictedMenuHrefs: ['search', 'activity']
        }
    },
    computed: {
        userStatus() {
            return window.tp?.pianoId?.isUserValid();
        }
    }
}
</script>

<style scoped>
a > .nav-item {
    color: #707070;
}
.offcanvas {
    width: 18vmax;
}
.offcanvas-body {
    padding: 0;
    margin: 0;
}
.myaccount-top {
    padding: 25px 0;
    background-color: #333;
    height: max-content;
    font-family: "Fira Sans";
}
.myaccount-menus {
    font-family: "Fira Sans";
    font-size: 14px;
    color: #707070;
}
.nav-item {
    cursor: pointer;
}
.mobile-menus {
    display: none;
}
@media only screen and (max-width: 900px) {
    .offcanvas {
        width: 30vmax;
    }
    .mobile-menus {
        display: block;
    }
}
</style>